<template>
  <div class="container-fluid px-0 h-100">
    <div class="row w-100 mx-0 h-100">
      <div class="col-12 col-md-7">
        <div class="row w-100 h-100 mx-0 align-items-center">
          <div class="col-12 px-0">
            <div class="row w-100">
              <div
                class="col-12 px-0"
                style="height: 30px; margin-bottom: 60px"
              >
                <img
                  :src="$store.state.logoImageUrl"
                  style="width: 200px"
                  alt="logo"
                  class="img-fluid"
                />
              </div>
            </div>
            <div
              class="col-12"
              style="font-weight: 700; font-size: 18px"
            >
              {{ $store.state.universityName }}
            </div>
            <div
              class="col-12"
              style="font-weight: 500; color: rgba(0, 0, 0, 0.4)"
            >
              Authorize Account Login
            </div>
            <div
              class="col-12 mt-5"
              style="
                font-weight: 500;
                color: rgba(0, 0, 0, 0.8);
                position: relative;
                font-size: 13px;
                width: ;
              "
            >
              <div>
                A Verification code was sent to the
                {{ authMethod == "PHONE" ? "Phone Number" : "email" }}
                <b>{{
                  authMethod == "PHONE" ? formattedPhone : formattedEmail
                }}</b>
                <br />
                Check Your
                {{ authMethod == "PHONE" ? "Phone" : "Inbox" }}
                and enter it below
              </div>
            </div>
            <div class="col-12 mt-3">
              <input
                type="text"
                placeholder="Verification Code"
                maxlength="6"
                class="legacy-input bg-white"
                @keyup.enter="onVerifyCode"
                v-model="verificationCode"
                style="width: 287px; height: calc(2.55rem) !important"
              />
            </div>

            <div class="col-12 mt-2">
              <button
                type="button"
                id="resendCodeButton"
                @click="onVerifyCode"
                v-if="!isBusy"
                :disabled="!isCodeValid"
                class="btn z-depth-0"
                v-ripple="'rgba(255, 255, 255, 0.35)'"
                style="
                  width: 287px;
                  text-transform: capitalize;
                  height: 45px;
                  border-radius: 4px;
                  background-color: var(--el-app-primary);
                "
              >
                <span style="position: relative; bottom: 1px">Verify Code</span>
              </button>
              <ScaleOut
                v-else
                :background="'#164B70'"
              />
            </div>
            <DidntReceiveCodeDialog
              :tryInSeconds="tryInSeconds"
              :email="userData.email"
              :phoneNumber="userData.phoneNumber"
              :temporaryToken="$route.params.temporaryToken"
              :authMethod="authMethod"
              @on-session-expired="onSessionExpired"
              @on-new-phone-code-sent="onNewPhoneCodeSent"
              @on-new-email-code-sent="onNewEmailCodeSent"
              @on-auth-method-change="onAuthMethodChange"
            />
          </div>

          <AnonymousUserContactUsDialog />
        </div>
      </div>
      <div class="col-4 d-md-block d-none px-0 h-100">
        <div
          class="row w-100 h-100 mx-0 align-items-center"
          style="border-radius: 0px 0px 0px 0px"
        >
          <div class="col-12 px-0">
            <img
              src="../assets/img/enter-code.jpg"
              alt=""
              style="height: 100vh; object-fit: contain"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScaleOut from "@/components/scale-out-component.vue";
import AnonymousUserContactUsDialog from "../components/dialogs/anonymous-user-contact-us-dialog";
import DidntReceiveCodeDialog from "../components/dialogs/didnt_receive_code_dialog";
import jwt_decode from "jwt-decode";

import firebase from "firebase/app";
import "firebase/auth";
let MAX_RESEND_WAIT_IN_SEC = 60;

export default {
  components: {
    ScaleOut,
    AnonymousUserContactUsDialog,
    DidntReceiveCodeDialog,
  },
  data() {
    return {
      verificationCode: "",
      confirmationResult: null,
      authMethod: "",
      // temporaryToken: "",
      tryInSeconds: MAX_RESEND_WAIT_IN_SEC,
      isBusy: false,
    };
  },

  computed: {
    isCodeValid() {
      return RegExp(/^[0-9]{6}$/).test(this.verificationCode);
    },
    userData() {
      return jwt_decode(this.$route.params.temporaryToken);
    },
    formattedEmail() {
      if (!this.userData.email) return "";
      const emailParts = this.userData.email.split("@");
      const visiblePart = emailParts[0].substr(0, 3);
      const invisblePart = emailParts[0].substr(3).replace(/\w/g, "*");
      return `${visiblePart}${invisblePart}@${emailParts[1]}`;
    },
    formattedPhone() {
      const phoneNumber = this.userData.phoneNumber;
      if (!phoneNumber) return "";

      const visiblePart = phoneNumber.substr(0, 6);
      const invisblePart = phoneNumber
        .substr(3, phoneNumber.length - 7)
        .replace(/\w/g, "*");
      return `${visiblePart}${invisblePart}${phoneNumber.substr(
        phoneNumber.length - 1
      )}`;
    },
  },

  mounted() {
    //If we have no temporaryToken in memory it means the user refreshed so we take them back to Login Screen
    if (!this.$route.params.temporaryToken || !this.$route.params.authMethod) {
      return this.$router.replace({ name: "Login" });
    }

    this.setUpFirebaseRECAPTCHA();
    this.confirmationResult = this.$route.params.confirmationResult;
    this.authMethod = this.$route.params.authMethod;
    this.startDidntReceiveCounter();
    // this.temporaryToken = this.$route.params.temporaryToken;
  },

  methods: {
    async setUpFirebaseRECAPTCHA() {
      // const self = this;

      // // Start Firebase invisible reCAPTCHA verifier
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "resendCodeButton",
        {
          size: "invisible",
          callback: () => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            self.sendSMS();
          },
        }
      );
    },
    onAuthMethodChange(authMethod) {
      this.authMethod = authMethod;
    },
    onNewPhoneCodeSent(confirmationResult) {
      this.confirmationResult = confirmationResult;
      this.tryInSeconds = MAX_RESEND_WAIT_IN_SEC;
      this.startDidntReceiveCounter();
    },
    onNewEmailCodeSent() {
      this.tryInSeconds = MAX_RESEND_WAIT_IN_SEC;
      this.startDidntReceiveCounter();
    },
    onSessionExpired() {
      this.showFailedMessage(
        "Session Timed Out",
        "Your Login Attempt Session Expired, Please Log in again to continue"
      );
      this.$router.replace({ name: "Login" });
    },
    startDidntReceiveCounter() {
      let interval = setInterval(() => {
        --this.tryInSeconds;
        if (this.tryInSeconds == 0) clearInterval(interval);
      }, 1000);
    },

    async onVerifyCode() {
      if (!this.isCodeValid) return;
      if (this.authMethod == "PHONE") {
        try {
          this.isBusy = true;

          let result = await this.confirmationResult.confirm(
            this.verificationCode
          );

          if (result.user) {
            try {
              let request = await this.$http.post(
                "staff/on-phone-auth-verified",
                {
                  verification_id: this.confirmationResult.verificationId,
                  temporaryToken: this.$route.params.temporaryToken,
                }
              );

              if (
                request.data.success &&
                request.data.message == "LOGIN_SUCCESSFUL"
              ) {
                this.axios.defaults.headers.common[
                  "Authorization"
                ] = `Bearer ${request.data.accessToken}`;
                this.$store.commit("LOGIN_USER", request.data);

                this.$router.replace({ path: "/dashboard/home" });
                this.showSuccessMessage(
                  "Login Successful",
                  "You were successfully logged in"
                );
              } else if (request.data.message == "INVALID_INFO_SUPPLIED") {
                this.showFailedMessage(
                  "Session Timed Out",
                  "Your Login Attempt Session Expired, Please Log in again to continue"
                );
                return this.$router.replace({ name: "Login" });
              } else {
                this.$rollbar.warning(
                  "ADMIN FRONT END: Unxepected API response from OUR API on Login successful after Phone Auth",
                  request.data,
                  request
                );
                this.showFailedMessage(
                  "Authentication Failed",
                  "Unable to Authenticate Log in now, Please Try again"
                );
              }
            } catch (error) {
              this.showFailedMessage(
                "Authentication Failed",
                "Unable to Authenticate Log in now, Please Try again"
              );
            }
          } else {
            this.$rollbar.warning(
              "ADMIN FRONT END: Firebase Unable to retrieve User Object after Phone Auth"
            );

            this.showFailedMessage(
              "Authentication Failed",
              "Unable to Authenticate Log in now, Please Try again"
            );
            return this.$router.replace({ name: "Login" });
          }
        } catch (error) {
          if (error.message == "Network Error") {
            return this.showFailedMessage(
              "Connection failed",
              "A network error occurred, please try again."
            );
          }
          // alert("THERE WAS AN ERROR MAN");

          if (error.code == "auth/invalid-verification-code") {
            return this.showFailedMessage(
              "Incorrect Code",
              "The Code you entered is invalid, Please Review it and Try again"
            );
          } else {
            this.$rollbar.critical(
              "ADMIN FRONT END: Firebase confirmationResult.confirm got an unxepected  error",
              error
            );
            console.log(error);
            return this.showFailedMessage(
              "Unable to Authenticate",
              "Unable to Verify Code, Please and Try again later"
            );
          }
        } finally {
          this.isBusy = false;
        }
      } else {
        //Means they are verifying with email
        try {
          this.isBusy = true;
          let request = await this.$http.post("staff/on-email-verify-code", {
            code: this.verificationCode,
            temporaryToken: this.$route.params.temporaryToken,
          });

          if (
            request.data.success &&
            request.data.message == "LOGIN_SUCCESSFUL"
          ) {
            this.axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${request.data.accessToken}`;
            this.$store.commit("LOGIN_USER", request.data);

            this.$router.replace({ path: "/dashboard/home" });
            this.showSuccessMessage(
              "Login Successful",
              "You were successfully logged in"
            );
          } else if (request.data.message == "INCORRECT_CODE_SUPPLIED") {
            return this.showFailedMessage(
              "Incorrect Code",
              "The Code you entered is invalid, Please Review it and Try again"
            );
          } else if (request.data.message == "INVALID_INFO_SUPPLIED") {
            this.showFailedMessage(
              "Session Timed Out",
              "Your Login Attempt Session Expired, Please Log in again to continue"
            );
            return this.$router.replace({ name: "Login" });
          } else {
            this.$rollbar.warning(
              "ADMIN FRONT END: Unxepected API response from OUR API on Login successful after Phone Auth",
              request.data,
              request
            );
            this.showFailedMessage(
              "Authentication Failed",
              "Unable to Authenticate Log in now, Please Try again"
            );
            return this.$router.replace({ name: "Login" });
          }
        } catch (error) {
          if (error.message == "Network Error") {
            return this.showFailedMessage(
              "Connection failed",
              "A network error occurred, please try again."
            );
          }
          this.showFailedMessage(
            "Authentication Failed",
            "Unable to Authenticate Log in now, Please Try again"
          );
        } finally {
          this.isBusy = false;
        }
      }
    },
  },
};
</script>


<style>
.grecaptcha-badge {
  visibility: hidden;
}
</style>

<style scoped>
body,
div#app,
html {
  background-color: #fff !important;
  height: 100% !important;
}

[type="checkbox"][class*="filled-in"]:checked + label:after {
  border-color: var(--el-app-primary);
  background-color: var(--el-app-primary);
  z-index: 0;
}
</style>
