<template>
  <div class="text-left">
    <article>
      <section>
        <div class="container">
          <div class="staff_dialogs">
            <el-dialog title="Staff Details" 
              :visible="isVisible" 
              @open="onOpen()" 
              :destroy-on-close="true"
              :before-close="handleClose">
              <div v-loading="staff_submiting">
                <div v-if="!staffEditStatus">
                  <div>
                    <p><span style="font-weight: 700;">Name:</span> {{ staffRuleForm.first_name }} {{
                      staffRuleForm.last_name }}</p>
                    <p><span style="font-weight: 700;">Email:</span> {{ staffRuleForm.email }}</p>
                    <p><span style="font-weight: 700;">Phone Number:</span> {{ staffRuleForm.phone_number }}</p>
                    <p><span style="font-weight: 700;">Gender:</span> {{ staffRuleForm.gender }}</p>
                    <p><span style="font-weight: 700;">Roles:</span>

                    <ul style="margin:0px;">
                      <li v-for="role in staffRuleForm.checkedRoles" :key="role" style="">
                        <span v-if="role == 'LECTURER'">Lecturer</span>
                        <span v-else-if="role == 'AR'">Academic Registrar </span>
                        <span v-else-if="role == 'HR'">Human Resource</span>
                        <span v-else-if="role == 'DEAN'">Dean</span>
                        <!-- <span v-else-if="role == 'EXAMS'">Examinations</span> -->
                        <span v-else-if="role == 'HOD'">Head of Department</span>
                        <span v-else-if="role == 'QA'">Quality Assurance</span>
                        <span v-else-if="role == 'IT'">IT</span>
                        <span v-else-if="role == 'SUPER_ADMIN'">Super Admin</span>
                        <span v-else-if="role == 'BURSAR'">Bursar</span>
                        <span v-else-if="role == 'VC'">Vice Chancellor</span>
                        <span v-else-if="role == 'STUDENT_APPLICATIONS'">Student Applications</span>
                        <span v-else-if="role == 'DEAN_OF_STUDENTS'">Dean of Students</span>
                    <span v-else-if="role == 'NON_TEACHING_STAFF'">Non Teaching Staff</span>
                    <span v-else-if="role == 'CAFETERIA_ADMIN'">Cafeteria Admin</span>
                    <span v-else-if="role == 'WEBSITE_ADMIN'">Website Admin</span>
                      </li>
                    </ul>
                    </p>
                    <p v-if="OTPCode"><span style="font-weight: 700;">Login OTP:</span> {{ OTPCode }}</p>
                    <hr />
                    <div :class="$store.getters.canResetPassword ? 'show_list' : 'hide_list'" style="color: var(--el-app-primary); 
                                        display: flex; align-items: center; justify-content: space-between;">
                      <div style="cursor: pointer;">
                        <div @click="showPassword()" v-if="!showPasswordBool">
                          <span style="font-weight: 700;">Show Password<i class="el-icon-key"></i></span>
                        </div>
                        <div v-if="showPasswordBool">
                          <span style="font-weight: 700;">Password: </span>{{ StaffPassword }}
                        </div>
                      </div>

                      <div @click="resetPassword" class="btn-success"
                        style="cursor: pointer; font-weight: 600; border-radius: 5px; padding:3px 10px;">
                        Reset Password
                      </div>
                    </div>

                    <hr />
                    <div :class="$store.getters.canApproveStaff ? 'show_list' : 'hide_list'">
                      <label><span style="font-weight: 700;">{{ is_approved == true ? "Disapprove Staff" : "Approve Staff"
                      }}</span></label><br />
                      <div class="row">
                        <div class="col-md-6">
                          <el-select v-model="is_approved" @change="switchApprovedStatus" placeholder="Select">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                          </el-select>
                        </div>
                        <div class="col-md-6">
                          <span v-if="is_approved == 'APPROVED'" class="text-success"><small>Approved</small></span>
                          <span v-else-if="is_approved == 'REJECTED'" class="text-danger"><small>Rejected</small></span>
                          <span v-else-if="is_approved == 'PENDING'" class="text-primary"><small>Pending</small></span>
                        </div>
                      </div>
                      <hr />
                    </div>

                  </div>
                </div>

                <div v-if="staffEditStatus">
                  <el-form :model="staffRuleForm" label-position="top" :rules="staffRules" ref="staffRuleForm"
                    label-width="120px" class="demo-staffRuleForm">
                    <div class="row">
                      <div class="col-md-6">
                        <el-form-item label="First Name" prop="first_name">
                          <el-input v-model="staffRuleForm.first_name"></el-input>
                        </el-form-item>
                      </div>
                      <div class="col-md-6">
                        <el-form-item label="Last Name" prop="last_name">
                          <el-input v-model="staffRuleForm.last_name"></el-input>
                        </el-form-item>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <el-form-item label="Email" prop="email">
                          <el-input type="email" v-model="staffRuleForm.email"></el-input>
                        </el-form-item>
                      </div>
                      <div class="col-md-6">
                        <el-form-item label="Phone Number" prop="phone_number">
                          <el-input v-model="staffRuleForm.phone_number"></el-input>
                        </el-form-item>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <el-form-item label="Assign Staff Roles" prop="checkedRoles">
                          <el-select @keyup.enter="addStaff" @change="onRoleChange()" v-model="staffRuleForm.checkedRoles"
                            multiple style="width: 100%;">
                            <el-option v-for="role of this.staff_roles" :key="role.value" :label="role.label"
                              :value="role.value"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <div class="col-md-6" :class="[selectedRoles ? 'fd_chosen' : 'fd_Notchosen']">
                        <el-form-item label="Choose Dean's Faculty" prop="faculty"
                          :class="[selectedFaculty ? 'fd_faculty' : 'fd_Notfaculty']">
                          <el-select v-model="faculty" filterable placeholder="Choose A Faculty">
                            <el-option v-for="faculty in faculties" :key="faculty.faculty_id" :label="faculty.title"
                              :value="faculty.faculty_id">
                            </el-option>
                          </el-select>
                        </el-form-item>

                        <el-form-item label="Choose Department for this (HOD)" prop="department"
                          :class="[selectedDepartment ? 'fd_dept' : 'fd_Notdept']">
                          <el-select v-model="department" filterable placeholder="Choose A Department">
                            <el-option v-for="department in departments" :key="department.department_id"
                              :label="department.department_name" :value="department.department_id">
                            </el-option>
                          </el-select>
                        </el-form-item>

                      </div>

                      <div class="col-md-6">
                        <el-form-item label="Full Time/Part Time" prop="full_part_time">
                          <el-select @keyup.enter="addStaff" v-model="staffRuleForm.full_part_time">
                            <el-option label="Full Time" value="FULL_TIME"></el-option>
                            <el-option label="Part Time" value="PART_TIME"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <el-form-item label="Gender" prop="gender">
                          <el-radio-group v-model="staffRuleForm.gender">
                            <el-radio label="MALE">Male</el-radio>
                            <el-radio label="FEMALE">Female</el-radio>
                          </el-radio-group>
                        </el-form-item>
                      </div>
                    </div>

                    <el-form-item class="text-center">
                      <el-button type="primary" @click="editStaff('staffRuleForm')">Save Staff Changes</el-button>
                    </el-form-item>
                  </el-form>
                </div>

                <div class="text-center">
                  <small></small>
                </div>

                <div>
                  <el-button v-if="!staffEditStatus" type="primary" @click="showEditSection"
                    icon="el-icon-edit">Edit</el-button>
                  <el-button v-if="!staffEditStatus" type="success" @click="downloadLoadReport"
                    icon="el-icon-download">Download Load Report</el-button>
                  <el-button v-if="staffEditStatus" type="success" @click="showStatusSection"
                    icon="el-icon-warning-outline">Show staff info</el-button>
                  <el-button type="danger" disabled @click="deleteStaff" icon="el-icon-delete">
                    Delete</el-button>
                </div>

              </div>
            </el-dialog>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
// import { validationMixin } from "vuelidate";
// import { required } from "vuelidate/lib/validators";
export default {
  // mixins: [validationMixin],

  data() {
    return {
      // editStaffModal: false,

      loading: false,
      submiting: false,
      loadingError: false,
      staff_submiting: false,
      staff_edit_submiting: false,
      faculties: [],
      departments: [],
      selectedRoles: false,
      selectedFaculty: false,
      selectedDepartment: false,
      staffEditStatus: false,
      StaffPassword: "",
      showPasswordBool: false,
      OTPCode: "",

      faculty: "",
      department: "",

      is_approved: "",
      hod_dean: ["HOD", "DEAN"],
      staffFormData: {},

      options: [
        {
          value: "PENDING",
          label: "Pending",
        },
        {
          value: "APPROVED",
          label: "Approved",
        },
        {
          value: "REJECTED",
          label: "Rejected",
        },
      ],

      staff_roles: [
        {
          value: "AR",
          label: "Academic Registrar (AR)",
        },
        {
          value: "HR",
          label: "Human Resource (HR)",
        },
        {
          value: "BURSAR",
          label: "Bursar",
        },
        {
          value: "DEAN",
          label: "Dean",
        },
        {
          value: "HOD",
          label: "Head of Department (HOD)",
        },
        {
          value: "QA",
          label: "Quality Assurance",
        },
        {
          value: "LECTURER",
          label: "Lecturer",
        },
        {
          value: "SUPER_ADMIN",
          label: "Super Admin",
        },
        {
          value: "VC",
          label: "Vice Chancellor",
        },
        {
          value: "STUDENT_APPLICATIONS",
          label: "Student Applications",
        },
        {
          value: "DEAN_OF_STUDENTS",
          label: "Dean of Students",
        },
        {
          value: "NON_TEACHING_STAFF",
          label: "Non Teaching Staff",
        },
        {
          value: "CAFETERIA_ADMIN",
          label: "Cafeteria Admin",
        },
        {
          value: "WEBSITE_ADMIN",
          label: "Website Admin",
        },
      ],

      staffRuleForm: {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        checkedRoles: [],
        department: "",
        full_part_time: "",
        gender: "",
      },

      staffRules: {
        first_name: [
          {
            required: true,
            message: "First Name is required",
            trigger: "blur",
          },
        ],
        last_name: [
          { required: true, message: "Last Name is required", trigger: "blur" },
        ],
        email: [
          { required: true, message: "Email is required", trigger: "blur" },
        ],
        phone_number: [
          {
            required: true,
            message: "Phone Number is required",
            trigger: "blur",
          },
        ],
        checkedRoles: [
          { required: true, message: "Roles are required", trigger: "change" },
        ],
        full_part_time: [
          {
            required: true,
            message: "Full or Part Time is required",
            trigger: "change",
          },
        ],
        gender: [
          { required: true, message: "Gender is required", trigger: "change" },
        ],
      },
    };
  },

  props: {
    staffId: {
      type: String,
      required: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    // Iterate through each element in the
    // first array and if some of them
    // include the elements in the second
    // array then return true.
    findCommonElements3(arr1, arr2) {
      return arr1.some((item) => arr2.includes(item));
    },

    handleClose(done) {
      this.clearInputs();
      this.$emit("closeDialog");
      done?.call();
    },

    clearInputs() {
      this.staffRuleForm.first_name = "";
      this.staffRuleForm.last_name = "";
      this.staffRuleForm.email = "";
      this.staffRuleForm.phone_number = "";
      this.staffRuleForm.gender = "";
      this.staffRuleForm.checkedRoles = [];
      this.OTPCode = "";
    },

    onRoleChange() {
      this.faculty = "";
      this.department = "";
      console.log(this.staffRuleForm.checkedRoles);
      if (this.staffRuleForm.checkedRoles.includes("DEAN")) {
        this.selectedRoles = true;
        this.selectedFaculty = true;
        this.selectedDepartment = false;
      } else if (this.staffRuleForm.checkedRoles.includes("HOD")) {
        this.selectedRoles = true;
        this.selectedDepartment = true;
        this.selectedFaculty = false;
      } else if (
        this.staffRuleForm.checkedRoles.includes("DEAN") &&
        this.staffRuleForm.checkedRoles.includes("HOD")
      ) {
        this.selectedRoles = true;
        this.selectedFaculty = true;
        this.selectedDepartment = true;
      } else {
        this.selectedRoles = false;
      }
    },

    onEditRoleChange() {
      // this.editStaffModal = true;
      this.faculty = "";
      this.department = "";

      if (this.staffRuleForm.checkedRoles.includes("DEAN")) {
        this.selectedRoles = true;
        this.selectedFaculty = true;
        this.selectedDepartment = false;
      } else if (this.staffRuleForm.checkedRoles.includes("HOD")) {
        this.selectedRoles = true;
        this.selectedDepartment = true;
        this.selectedFaculty = false;
      } else if (
        this.findCommonElements3(this.staffRuleForm.checkedRoles, this.hod_dean)
      ) {
        this.selectedRoles = true;
        this.selectedFaculty = true;
        this.selectedDepartment = true;
      } else {
        this.selectedRoles = false;
      }
    },

    async onOpen() {
      try {
        this.staff_submiting = true;
        await Promise.all([
          this.fetchOneStaff(),
          this.getFaculties(),
          this.getDepartments(),
          this.getOtpVerificationCode()
        ]);
      } catch (error) {
        console.error(error);
        this.submitting = true;
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again"
          );
        }
        this.showFailedMessage(
          "Unable to get data",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.staff_submiting = false;
      }
    },

    async fetchOneStaff() {
      // if (!this.$store.getters.canStaffCrud) return;
      // eslint-disable-next-line no-useless-catch
      try {
        this.staff_submiting = true;
        let request = await this.$http.get(
          `staff/fetch-one/${this.staffId}`
        );
        if (
          request.data.success &&
          request.data.message == "STAFF_RETRIEVED_SUCCESSFULLY"
        ) {
          const staffData = request.data.staff
          this.staffRuleForm.first_name = staffData.first_name;
          this.staffRuleForm.last_name = staffData.last_name;
          this.staffRuleForm.email = staffData.email;
          this.staffRuleForm.phone_number = staffData.phone_number;
          this.staffRuleForm.gender = staffData.gender;
          this.staffRuleForm.checkedRoles = staffData.roles;

          this.faculty = staffData.role_faculty ? staffData.role_faculty.faculty_id : null;
          this.department = staffData.role_department ? staffData.role_department.department_id : null;
          this.staffRuleForm.full_part_time = staffData.fulltime_parttime;
          this.is_approved = staffData.is_approved;

          if (this.staffRuleForm.checkedRoles.includes("DEAN")) {
            this.selectedRoles = true;
            this.selectedFaculty = true;
            this.selectedDepartment = false;
          } else if (this.staffRuleForm.checkedRoles.includes("HOD")) {
            this.selectedRoles = true;
            this.selectedDepartment = true;
            this.selectedFaculty = false;
          } else if (
            this.findCommonElements3(this.staffRuleForm.checkedRoles, this.hod_dean)
          ) {
            this.selectedRoles = true;
            this.selectedFaculty = true;
            this.selectedDepartment = true;
          } else {
            this.selectedRoles = false;
          }

          this.staffEditStatus = false;
          this.showPasswordBool = false;
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        throw error;
      }
    },

    showEditSection() {
      this.staffEditStatus = true;
    },

    showStatusSection() {
      this.staffEditStatus = false;
    },

    deleteStaff() {
      this.$emit("deleteStaff", this.staffId);
    },

    async getFaculties() {
      // eslint-disable-next-line no-useless-catch
      try {
        this.staff_submiting = true;
        let request = await this.$http.get(`faculties/fetch-many`);
        if (
          request.data.success &&
          request.data.message == "Faculties fetched successfully"
        ) {
          this.faculties = request.data.faculties;
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        throw error;
      }
    },

    async getDepartments() {
      // eslint-disable-next-line no-useless-catch
      try {
        this.staff_submiting = true;
        let request = await this.$http.get(`departments/fetch-many`);
        if (
          request.data.success &&
          request.data.message == "DEPARTMENTS_RETRIEVED_SUCCESSFULLY"
        ) {
          this.departments = request.data.departments;
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        throw error;
      }
    },

    async editStaff(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.staffRuleForm.checkedRoles.includes("HOD")) {
            this.staffFormData = {
              first_name: this.staffRuleForm.first_name,
              last_name: this.staffRuleForm.last_name,
              email: this.staffRuleForm.email,
              phone_number: this.staffRuleForm.phone_number,
              gender: this.staffRuleForm.gender,
              roles: this.staffRuleForm.checkedRoles,
              department_id: this.department,
              fulltime_parttime: this.staffRuleForm.full_part_time,
            };

            if (this.department == "") {
              this.showWarningMessage(
                "Required",
                "Department field is required"
              );
              return;
            }
          } else if (this.staffRuleForm.checkedRoles.includes("DEAN")) {
            this.staffFormData = {
              first_name: this.staffRuleForm.first_name,
              last_name: this.staffRuleForm.last_name,
              email: this.staffRuleForm.email,
              phone_number: this.staffRuleForm.phone_number,
              gender: this.staffRuleForm.gender,
              roles: this.staffRuleForm.checkedRoles,
              faculty_id: this.faculty,
              fulltime_parttime: this.staffRuleForm.full_part_time,
            };

            if (this.faculty == "") {
              this.showWarningMessage("Required", "Faculty field is required");
              return;
            }
          } else {
            this.staffFormData = {
              first_name: this.staffRuleForm.first_name,
              last_name: this.staffRuleForm.last_name,
              email: this.staffRuleForm.email,
              phone_number: this.staffRuleForm.phone_number,
              gender: this.staffRuleForm.gender,
              roles: this.staffRuleForm.checkedRoles,
              fulltime_parttime: this.staffRuleForm.full_part_time,
            };
          }

          this.loading = true;
          this.staff_submiting = true;
          console.log(this.staffFormData);

          try {
            let response = await this.$http.patch(
              `staff/update-one/${this.staffId}`,
              this.staffFormData
            );

            if (
              response.data.success &&
              response.data.message == "STAFF UPDATED SUCCESSFULLY"
            ) {
              this.$emit("on-refresh");
              this.showSuccessMessage("Success", "Staff edited successfully!");
              this.loading = false;
              this.staff_edit_submiting = false;
              // this.editStaffModal = false;
              // this.clearInputs();
              this.handleClose()
            } else {
              this.showFailedMessage(
                "Unexpected Error",
                "An unexpected error occurred. Please try again"
              );
              this.staff_submiting = false;
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.showFailedMessage(
                "Connection failed",
                "A network error occurred, please try again."
              );
            } else if (error.response) {
              if (error.response.data.message == "PHONE_NUMBER_EXISTS") {
                this.showWarningMessage(
                  "Duplicate Phone Number",
                  "Phone number already in use. Please choose another one"
                );
              } else if (error.response.data.message == "INVALID_EMAIL") {
                this.showWarningMessage(
                  "Invalid Email",
                  "You entered an invalid Email"
                );
              } else if (
                error.response.data.message == "INVALID PHONE NUMBER"
              ) {
                this.showWarningMessage(
                  "Invalid Phone Number",
                  "The phone number you provided is invalid"
                );
              } else if (
                error.response.data.message == "STAFF ROLES ARE REQUIRED"
              ) {
                this.showWarningMessage(
                  "Roles Required",
                  "Staff Roles are required"
                );
              } else if (
                error.response.data.message ==
                "FACULTY_ID_IS_REQUIRED_FOR_A_DEAN"
              ) {
                this.showWarningMessage(
                  "Faculty Required",
                  "For a Dean Role, a faculty must be provided"
                );
              } else if (
                error.response.data.message ==
                "DEPARTMENT_ID_IS_REQUIRED_FOR_HOD"
              ) {
                this.showWarningMessage(
                  "Department Required",
                  "For an HOD, Department is required"
                );
              } else if (
                error.response.data.message == "UNACCEPTED ROLE PROVIDED"
              ) {
                this.showWarningMessage(
                  "Not Acceptable",
                  "One of the Roles you entered is not allowed"
                );
              } else if (
                error.response.data.message == "EMAIL_ALREADY_IN_USE"
              ) {
                this.showWarningMessage(
                  "Already in Use",
                  "The supplied email is already in use by another staff member"
                );
              } else if (error.response.data.message == "STAFF NOT FOUND") {
                this.showWarningMessage(
                  "Not Found",
                  "Unable to find the selected Staff Member"
                );
              }
              else if (error.response.data.message == "STAFF CAN NOT BE EDITED") {
                this.showWarningMessage(
                  "Not Allowed",
                  "You are not allowed to edit this staff member"
                );
              } else {
              console.log(error);
              this.showFailedMessage(
                "Unexpected Error",
                "An unexpected error occurred. Please try again"
              );
            }
            } else {
              console.log(error);
              this.showFailedMessage(
                "Unexpected Error",
                "An unexpected error occurred. Please try again"
              );
            }
            this.staff_submiting = false;
          } finally {
            this.loading = false;
            this.staff_submiting = false;
          }
        } else {
          return false;
        }
      });
    },

    async switchApprovedStatus() {
      let staffApproveStatus = {
        is_approved: this.is_approved,
      };
      try {
        this.staff_edit_submiting = true;
        let request = await this.$http.patch(
          `staff/do-approval/${this.staffId}`,
          staffApproveStatus
        );
        if (
          request.data.success &&
          request.data.message == "STAFF_UPDATED_SUCESSFULLY"
        ) {
          this.$emit("on-refresh");
          this.showSuccessMessage(
            "Success",
            "Staff changes saved successfully"
          );
        } else if (request.data.message == "NO_PERMISSION_TO_RESOURCE") {
          this.showWarningMessage(
            "Success",
            "You have no permission to approve staff."
          );
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        } else if (error.message.message == "NO_PERMISSION_TO_RESOURCE") {
          this.showWarningMessage(
            "Success",
            "You have no permission to approve staff."
          );
        }
        this.showFailedMessage(
          "Unable to update Staff",
          "An unexpected error occurred, please try again"
        );
      } finally {
        this.staff_edit_submiting = false;
      }
    },

    async showPassword() {
      this.StaffPassword = "";
      try {
        this.staff_edit_submiting = true;
        let request = await this.$http.get(
          `/staff/default_password/${this.staffId} `
        );
        if (request.data.message == "STAFF_PASSWORD_RETRIEVED_SUCCESFULLY") {
          this.showPasswordBool = true;
          this.StaffPassword = request.data.default_pass;
        } else if (request.data.message == "STAFF_HAS_NO_DEFAULT_PASSWORD") {
          this.showWarningMessage(
            "No default password",
            "Staff has already changed password."
          );
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          "Unable to load Password",
          "An unexpected error occurred, please try again"
        );
      } finally {
        this.staff_edit_submiting = false;
      }
    },

    async resetPassword() {
      this.StaffPassword = "";
      try {
        this.staff_edit_submiting = true;
        let request = await this.$http.patch(
          `/staff/default_password/${this.staffId} `
        );
        if (
          request.data.message == "STAFF_DEFAULT_PASSWORD_CHANGED_SUCCESSFULLY"
        ) {
          this.showPasswordBool = true;
          this.StaffPassword = request.data.default_password;
          return this.showSuccessMessage(
            "Success",
            "Password reset successfully"
          );
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          "Unable to load Password",
          "An unexpected error occurred, please try again"
        );
      } finally {
        this.staff_edit_submiting = false;
      }
    },

    async downloadLoadReport() {
      try {
        this.loading = true;
        this.staff_edit_submiting = true;
        let request = await this.$http.get(
          `/staff/excel-sheet-reports/get-modules-taught?lecturer_id=${this.staffId}`
        );
        if (
          request.data.success &&
          request.data.message == "REPORT GENERATED SUCCESSFULLY"
        ) {
          this.showSuccessMessage("Success", "Downloading load report ...");
          this.loading = false;
          this.staff_edit_submiting = false;
          this.showEditModal = false;

          const link = document.createElement("a");
          link.href = request.data.link;
          link.click();
          return;
        } else throw "UNEXPECTED ERROR OCCURRED";
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        if (error.response) {
          if (error.response.data.message == "LECTURER NOT FOUND") {
            return this.showWarningMessage(
              "Not Found",
              "Unable to find the selected Lecturer"
            );
          } else if (error.response.data.message == "STAFF MODULES NOT FOUND") {
            return this.showWarningMessage(
              "No Modules",
              "The selected staff does not teach any modules"
            );
          }
        }

        this.showFailedMessage(
          "Unable to download Load report",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.submiting = false;
      }
    },

    // Get OTP Verification Code for a Staff
    async getOtpVerificationCode() {
      try {
        this.loading = true;
        let request = await this.$http.get(`staff/get-staff-otp/${this.staffId}`);
        if (
          request.data.success &&
          request.data.message == "Two step verification code fetched successfully"
        ) {
          this.OTPCode = request.data.code;
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          "Unable to verify code",
          "An unexpected error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

input[type="text"],
input[type="email"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.to-an-array {
  display: flex;
  align-items: center;
}

.to-an-array>div {
  margin-right: 20px;
}

.fd_chosen {
  display: block;
}

.fd_Notchosen {
  display: none;
}

.fd_faculty,
.fd_dept {
  display: block;
}

.fd_Notfaculty,
.fd_Notdept {
  display: none;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_add_section {
    display: block;
  }

  .addBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
  }

  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .search_add_section {
    display: block;
  }

  .addBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
  }

  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
.staff_dialogs .el-dialog {
  width: 60%;
}

.search_by_input {
  width: 400px !important;
}

.el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px;
}

label {
  margin-bottom: 0px !important;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .staff_dialogs .el-dialog {
    width: 50%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .staff_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .staff_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .staff_dialogs .el-dialog {
    width: 90%;
  }
}
</style>
