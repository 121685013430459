<template>
    <div class="container-fluid px-0 h-100" style="text-align: left;">
        <article>
            <section style="padding-top: 50px;">
                <div class="container">
                    <div class="row w-100 mb-4">
                        <div class="col-12 pl-3 px-0 mb-0 text-left">
                            <BreadCrumbComponent :pageTitles="['Retakes']" />
                        </div>
                    </div>

                    <div class="row w-100">
                        <div class="col-12 text-left">
                            <div style="font-size: 20px; font-weight: 400; color: var(--el-app-primary); margin-bottom: 15px; ">
                                <strong>Retakes</strong>
                            </div>
                        </div>
                    </div>

                    <div class="search_add_section">
                        <div class="">
                            <input type="text" v-model.trim="filters[0].value" placeholder="Search by Title"  class="search_by_input" spellcheck="off"/>
                        </div>
                    </div>
                    <hr/>

                    <div v-loading="deleting" style=" width: 100%">
                        <data-tables :data="retakes" :table-props="tableProps" :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }" type="expand" :filters="filters">
                            <div slot="empty">
                                <ScaleOut v-if="loading && !loadingError" class="mt-3" :background="'#164B70'"/>
                                <div @click="getBatches" v-else-if="!loading && loadingError" style="cursor: pointer">
                                    Unable to Load Retakes Now. Please click here to retry
                                </div>
                                <div v-if="!loading && !loadingError">No Retakes</div>
                            </div>

                            <el-table-column label="#" :show-overflow-tooltip="true" label-class-name="text-center" class-name="text-center" width="80px">
                                <template slot-scope="scope">
                                    <span>{{ scope.$index + 1 }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column label="Student" :show-overflow-tooltip="true" label-class-name="text-left" width="auto">
                                <template slot-scope="scope">
                                    <div style="display:flex; align-items: center;">
                                        <div  style="width:40px; height:40px; border-radius: 50%;">
                                            <div v-if="scope.row.student.profile_picture" style="display: flex; align-items:center; justify-content: center; background-color: var(--vu-red); color: white; width:40px; height:40px; object-fit:cover; border-radius: 100%;">
                                                <img :src="scope.row.student.profile_picture" class="img-fluid" style="width:40px; height:40px; object-fit:cover; border-radius: 100%;" />
                                            </div>
                                            <div v-if="scope.row.student.profile_picture == null" style="display: flex; align-items:center; justify-content: center; background-color: var(--vu-red); color: white; width:40px; height:40px; object-fit:cover; border-radius: 100%;">
                                                <strong>{{ getInitials(`${scope.row.student.full_names}` ) }}</strong>
                                            </div>
                                        </div>
                                        <div style="margin-left: 10px;">
                                            <span>{{ scope.row.student.full_names }}</span><br/>
                                            <!-- <span showing years on edit style="font-weight: 600;">Email: </span><span>{{ scope.row.email }}</span> -->
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column label="Intake" :show-overflow-tooltip="true" label-class-name="text-left" class-name="text-left">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.batch.course_title }}</span><br/>
                                    <span>{{ scope.row.batch.year }} </span>
                                    <span>{{ toMonthName(scope.row.batch.month) }}</span><br/>
                                    <span>{{ scope.row.batch.cohort }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column label="Course Unit" :show-overflow-tooltip="true" label-class-name="text-left" class-name="text-left">
                                <template slot-scope="scope">
                                    <span>
                                        {{ scope.row.course_unit.title }} 
                                        ({{ scope.row.course_unit.course_unit_code }})
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column label="Assigned Ratake Intake" :show-overflow-tooltip="true" label-class-name="text-left" class-name="text-left">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.retake_batch.course_title }}</span><br/>
                                    <span>{{ scope.row.retake_batch.year }} </span> 
                                    <span>{{ toMonthName(scope.row.retake_batch.month) }}</span><br/>
                                    <span>{{ scope.row.retake_batch.cohort }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column label="Actions" :show-overflow-tooltip="true" label-class-name="text-center" class-name="text-center">
                                <template slot-scope="scope">
                                    <el-row>
                                        <el-tooltip content="Assign Intake" placement="top">
                                            <el-button type=""
                                            style="background-color: blue; color: white;"
                                            @click="addAssignIntakeModal(scope.row.student_semester_course_unit_id)"
                                            circle>
                                             <i class="fas fa-swatchbook"></i></el-button>
                                        </el-tooltip>
                                        <el-tooltip content="Delete" placement="top">
                                            <el-button type="danger" :disabled= "true" icon="el-icon-delete" circle></el-button>
                                        </el-tooltip>
                                    </el-row>
                                </template>
                            </el-table-column>

                        </data-tables>
                    </div>
                </div>
            </section>

            <section>
                <div class="container">
                    <div class="retakes_dialogs">
                        <el-dialog title="" :visible.sync="assignIntakeModal" :destroy-on-close="true" :before-close="handleClose">
                            <div v-loading="submiting">

                                <div class="row">
                                    <div class="col-md-1"></div>
                                    <div class="col-md-10">
                                        <label for="batch">Assign Student to an Intake to take Retake</label>
                                        <el-select filterable :class="{ 'input-error': $v.batch.$error }" @keyup.enter="assignBatch" v-model="$v.batch.$model" placeholder="Choose Intake"  style="width: 100%;">
                                            <el-option 
                                                v-for="item in batches"
                                                :key="item.course_intake_batch_id"
                                                :label="`${item.year} ${toMonthName(item.month)}` + ' Intake ' +' / '+ `${item.cohort}`"
                                                :value="item.course_intake_batch_id">
                                            </el-option>
                                        </el-select>
                                        <span v-if="$v.batch.$error" style="font-size: 0.7em; color: red">{{ batchError }}</span>
                                    </div>
                                    <div class="col-md-1"></div>
                                </div>
                                    
                                <div class="form-group text-center" style="padding-top:30px;">
                                    <el-button style="background-color: var(--el-app-primary); position: relative; bottom: 9px; color: white;" class="z-depth-0" v-ripple="'rgba(255, 255, 255, 0.35)'" @click="assignBatch" :disabled="!$v.batch.required">Assign Intake</el-button>
                                </div>

                                <div class="text-center">
                                    <div class="text-center">
                                        <small></small>
                                    </div>
                                    <div class="text-center">
                                        <!-- <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                                    </div>
                                </div>
                            </div>
                        </el-dialog>
                    </div>
                </div>
            </section>
        </article>
    </div>
</template>

<script>

    import { validationMixin } from "vuelidate";
    import { required } from "vuelidate/lib/validators";
    import ScaleOut from "@/components/scale-out-component.vue";
    import BreadCrumbComponent from "../components/bread-crumb-component";
    import { format, parseISO } from "date-fns";
    import getMonthName from "../helpers/getMonthName";

  export default { 
    components: { ScaleOut, BreadCrumbComponent },
    mixins: [validationMixin],

      data() {
          return {
            assignIntakeModal: false,
            loading: false,
            loadingError: false,
            submiting: false,
            deleting: false,
            responseData: "",
            
            batch: "",
            student_semester_course_unit_id: "",
            retakes: [],
            batches: [],

            tableData: [],
            tableProps: {
            border: true,
            stripe: true,
            },

            filters: [
                {
                    prop: "student.full_names",
                    value: "",
                },
            ],

            pickerOptions: {
                shortcuts: [{
                    text: 'Last week',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last month',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last 3 months',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },

        };
      },

      computed: {

        batchError() {
            if (!this.$v.Batch.required) {
            return "*Batch is required";
          }
          return "";
        },
        
        createdBy() {
          return (creator)=> {
            if(!creator) return "N/A";
            return `${creator.first_name} ${creator.last_name}`;
          }
        },

        formattedDate() {
          return (dateString) => format(parseISO(dateString), "do MMMM yyyy");
        },

        getInitials() {
            return (nameString) => {
            //  format(parseISO(dateString), "do MMMM yyyy");
            var names = nameString.split(" "),
                initials = names[0].substring(0, 1).toUpperCase();
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials;
            };
        },

      },

      validations: {
          
        batch: { required },

      },

      mounted() {
          this.getRetakes();
      },

      methods: {

        handleClose(done) {
          this.clearInputs();
          this.$v.$reset();
          done();
        },

        clearInputs() {
          this.academic_year = "";
          this.semester = "";
          this.start_end_date = "";
        },

        toMonthName(monthNum) {
            return getMonthName(monthNum, true);
        },

        async getRetakes() {
            try {
                this.loading = true;
                this.loadingError = false;
                let request = await this.$http.get(`semesters/get-current-semester-retakes`);
                if ( request.data.success && request.data.message == "RETAKES_RETRIEVED_SUCEESSFULLY" ) { 
                    this.retakes = request.data.retakes;
                } 
                else { this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE"; }  
            } catch (error) {
                if (error.message == "Network Error") {

                    this.loadingError = true;
                    return this.showFailedMessage("Connection failed","A network error occurred, please try again.");
                
                }

                this.loadingError = true; 
                this.showFailedMessage("Unable to load Retakes", "An unexpected error occurred, please try again");
            } finally {
                this.loading = false; 
            }
        },

        async getIntakeBatchesForRetake(student_semester_course_unit_id) {
            try {
                this.loading = true;
                let request = await this.$http.get(`semesters/fetch-possible-batches-for-retake/${student_semester_course_unit_id}`);
                if ( request.data.success && request.data.message == "BATCHES_FETCHED_SUCCESS_FULLY" ) { 
                    this.batches = request.data.batches;
                }else { this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE"; }  
            } catch (error) {
                if (error.message == "Network Error") {
                    return this.showFailedMessage("Connection failed","A network error occurred, please try again.");
                }
                this.showFailedMessage("Unable to load Intakes", "An unexpected error occurred, please try again");
            } finally {
                this.loading = false;
            }
        },

        addAssignIntakeModal(student_semester_course_unit_id) {
            this.assignIntakeModal = true;
            this.student_semester_course_unit_id =  student_semester_course_unit_id;
            this.getIntakeBatchesForRetake(student_semester_course_unit_id);
        },
        
        assignIntake(student_semester_course_unit_id) {
            alert(student_semester_course_unit_id);
        },

        assignBatch() {
            this.submiting = true;
            let batchForm = {
                retake_course_intake_batch_id: this.batch,
                student_semester_course_unit_id: this.student_semester_course_unit_id,
            }
            this.$http.patch(`semesters/assign-retake-batch`, batchForm).then(
                (response) => {
                if (
                    response.data.success && response.data.message == "BATCH_ASSIGNED_SUCCESSFULLY"
                ) {
                    this.submiting = false;
                    this.assignIntakeModal = false,
                    this.getRetakes();
                    this.showSuccessMessage("Success", "Assigned Intake successfully");
                }
                },
                (error) => { // eslint-disable-line no-unused-vars
                this.submiting = false;
                if (error.message == "Network Error") {
                    return this.showFailedMessage(
                    "Connection failed",
                    "A network error occurred, please try again."
                    );
                }
                this.showFailedMessage(
                    "Unable to Assign Intakes",
                    "An unexpected error occurred, please try again"
                );
                }
            );
        }
    }
  }

</script>

<style scoped>
  * {
    box-sizing: border-box;
  }
  
  input[type=text], select, textarea {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #FFFFFF;
    font-size: .9em;
  }

  .search_add_section {
    display: flex; align-items: center; justify-content: space-between;
  }

  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .search_add_section {
      display: block; 
    }
    .addbtn {
      width: 100%;
      margin-top: 10px;
    }
      .search_by_input {
        width: 100% !important;
      }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .search_add_section {
      display: block;
    }
    .addbtn {
      width: 100%;
      margin-top: 10px;
    }
      .search_by_input {
        width: 100% !important;
      }
  }
</style>

<style>
  
  .retakes_dialogs .el-dialog {
    width: 40%;
  }

  .search_by_input {
    width: 400px !important;
  }


  .retakes_dialogs .el-select .el-input__inner {
    cursor: pointer;
    height: 36px !important;
    width: 100% !important;
    padding-left: 10px !important;
    text-transform: capitalize;
  }
  .retakes_dialogs .el-button--primary {
    color: #fff;
    background-color: #409eff !important;
    border-color: #409eff !important;
  }

  el-table th > .cell {
    color: black !important;
    font-weight: 500;
  }
  .el-table .cell {
    font-size: 0.9em;
    line-height: 23px;
    font-weight: 300;
  }

  .retakes_dialogs .el-input input {
    font-size: 0.8rem !important;
    font-weight: 400 !important;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  }

  .retakes_dialogs .el-input input:focus {
    color: #495057 !important;
    background-color: #fff !important;
    border-color: #80bdff !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
  }

  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .retakes_dialogs .el-dialog {
      width: 50%;
    }
  }

  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .retakes_dialogs .el-dialog {
      width: 70%;
    }
  }

  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .retakes_dialogs .el-dialog {
      width: 90%;
    }
  }

  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .retakes_dialogs .el-dialog {
      width: 90%;
    }

  }
</style>
