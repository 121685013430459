import Vue from 'vue'
import Vuex from 'vuex'
import { elearningStore } from './elearningStore'
import { INITIALIZE_STORE, LOGIN_USER, LOGOUT_USER, SAVE_SEARCH_RESULTS, REFRESH_ACCESS_TOKEN, SHOW_PERMISSION_ERROR, SET_UNREAD_MESSAGES_COUNT } from "./mutation-types"
import jwt_decode from "jwt-decode";
//@ts-ignore
import { role } from "./role";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userId: "",
    userInitials: "",
    profilePicture: "",
    accessToken: "",
    deviceToken: "",
    unReadMessagesCount: "",
    universityName: "VICTORIA UNIVERSITY",
    logoImageUrl: require("../assets/img/vu-logo-with-words.png"),
    backgroundImage: require("../assets/img/login-img.jpg"),
    search_results: [],
    userRoles: [],
    // userRoles: ['VC'],
    firstName: "",
    isPesamoniUser: false,
    permissionError: null,

    //THE ROLES FOR THE ADMINS
    student_crud: [role.AR, role.VC, role.SUPER_ADMIN, role.IT, role.STUDENT_APPLICATIONS],
    student_read: [role.AR, role.VC, role.BURSAR, role.DEAN, role.HOD, role.IT, role.SUPER_ADMIN, role.DEAN_OF_STUDENTS],
    grant_access: [role.AR, role.BURSAR, role.VC, role.SUPER_ADMIN],
    delete_student: [role.AR, role.VC, role.SUPER_ADMIN],
    activate_student: [role.AR, role.VC, role.SUPER_ADMIN],
    staff_read: [role.VC, role.AR, role.HR, role.DEAN, role.HOD, role.IT, role.SUPER_ADMIN],
    approve_staff: [role.AR, role.VC, role.DEAN, role.SUPER_ADMIN],
    assign_staff: [role.DEAN, role.HOD, role.VC, role.SUPER_ADMIN, role.AR],
    faculties_cUnits_read: [role.AR, role.VC, role.SUPER_ADMIN, role.STUDENT_APPLICATIONS],
    faculties_cUnits_CRUD: [role.AR, role.VC, role.SUPER_ADMIN],
    view_topics: [role.AR, role.QA, role.VC, role.SUPER_ADMIN, role.STUDENT_APPLICATIONS],
    create_batch_read: [role.AR, role.VC, role.DEAN, role.HOD, role.SUPER_ADMIN, role.BURSAR, role.STUDENT_APPLICATIONS, role.QA],
    live_lectures_read: [role.AR, role.QA, role.VC, role.DEAN, role.HOD, role.IT, role.SUPER_ADMIN, role.DEAN_OF_STUDENTS],
    help_messages: [role.AR, role.HR, role.DEAN, role.HOD, role.QA, role.IT, role.VC, role.SUPER_ADMIN, role.DEAN_OF_STUDENTS],
    notice_board: [role.AR, role.HR, role.DEAN, role.HOD, role.QA, role.VC, role.SUPER_ADMIN],
    notice_board_read: [role.VC, role.SUPER_ADMIN],
    staff_request: [role.HR, role.VC, role.SUPER_ADMIN],
    resource_content: [role.QA, role.AR, role.DEAN, role.HOD, role.VC, role.SUPER_ADMIN],
    view_course_work: [role.AR, role.QA, role.DEAN, role.HOD, role.VC, role.SUPER_ADMIN],
    view_resits_and_retakes: [role.AR, role.QA, role.DEAN, role.HOD, role.VC, role.SUPER_ADMIN, role.BURSAR],
    course_work_CUD: [role.AR, role.DEAN, role.HOD, role.VC, role.SUPER_ADMIN],
    view_grading: [role.EXAMS],
    examinations: [role.EXAMS],
    student_applications: [role.AR, role.QA, role.STUDENT_APPLICATIONS, role.BURSAR, role.SUPER_ADMIN, role.VC],
    view_financial_reports: [role.BURSAR, role.SUPER_ADMIN],
    configurations: [role.VC, role.SUPER_ADMIN, role.HEAD_BURSAR],
    payments_read: [role.VC, role.SUPER_ADMIN, role.BURSAR],
    admit_student: [role.AR, role.VC, role.SUPER_ADMIN],
    can_approve_or_reject_mark_sheet: [role.EXAMS, role.HOD, role.DEAN],
    can_approve_or_reject_result_sheet: [role.EXAMS, role.DEAN],
    can_publish_result_sheet: [role.EXAMS, role.AR],
    examination_role: [role.EXAMS, role.AR, role.DEAN, role.HOD, role.QA],
    cafeteria_admin: [role.CAFETERIA_ADMIN, role.SUPER_ADMIN],
    view_programme_change_requests: [role.AR, role.STUDENT_APPLICATIONS, role.DEAN, role.SUPER_ADMIN, role.BURSAR, role.VC],
    view_intake_change_requests: [role.AR, role.STUDENT_APPLICATIONS, role.SUPER_ADMIN, role.BURSAR, role.VC],
    view_session_change_requests: [role.AR, role.STUDENT_APPLICATIONS, role.SUPER_ADMIN, role.BURSAR, role.VC],
    //THE ROLES FOR THE ADMINS
  },

  mutations: {

    [SET_UNREAD_MESSAGES_COUNT]: (state, payload) => {
      state.unReadMessagesCount = payload;
    },
    [SHOW_PERMISSION_ERROR]: (state) => {
      //@ts-ignore
      state.permissionError = +(new Date());
    },
    [LOGIN_USER]: (state, { accessToken, fbDeviceToken }) => {
      //Save the Device Token as we need it for Single User Sessions
      state.deviceToken = fbDeviceToken;

      try {
        //@ts-ignore
        var decoded: String = jwt_decode(accessToken);
        //@ts-ignore
        state.userId = decoded.user_id;
        //@ts-ignore
        state.profilePicture = decoded.pp;
        //@ts-ignore
        state.userInitials = decoded.ui;
        //@ts-ignore
        state.firstName = decoded.fn;
        //@ts-ignore
        state.userRoles = decoded.roles;
        state.accessToken = accessToken;
        //@ts-ignore
        state.isPesamoniUser = decoded.isP;

        //@ts-ignore
        state.fullName = decoded.fn;

        //@ts-ignore

      } catch (error) {
        //Nothing to do
      }

      //Save The Access Token in local Storage 
      (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).setItem("a-at", accessToken);
      (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).setItem("a-dt", fbDeviceToken);
    },
    [REFRESH_ACCESS_TOKEN]: (state, { accessToken }) => {
      //Save The Access Token in local Storage 
      (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).setItem("a-at", accessToken);

      try {
        //@ts-ignore 
        var decoded: String = jwt_decode(accessToken);
        //@ts-ignore
        state.userId = decoded.user_id;
        //@ts-ignore
        state.profilePicture = decoded.pp;
        //@ts-ignore
        state.userInitials = decoded.ui;
        //@ts-ignore
        state.userInitials = decoded.ui;
        //@ts-ignore
        state.firstName = decoded.fn;
        //@ts-ignore
        state.userRoles = decoded.roles;
        state.accessToken = accessToken;
        //@ts-ignore
        state.isPesamoniUser = decoded.isP;

        //@ts-ignore
        state.fullName = decoded.fn;

        //@ts-ignore

      } catch (error) {
        //Nothing to do 
      }
    },
    [LOGOUT_USER]: (state) => {
      //Save The Access Token in local Storage
      state.userId = "";
      state.accessToken = '';
      state.userInitials = '';
      state.profilePicture = '';
      state.firstName = '';
      state.userRoles = [];
      //@ts-ignore
      state.search_results = {};
      (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).removeItem("a-dt");
      (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).removeItem("a-at");
      localStorage.removeItem("isPersistent");
    },
    [INITIALIZE_STORE]: (state) => {
      //Retrieve Device Token
      //@ts-ignore
      state.deviceToken = (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).getItem("a-dt");      //Attempt to retrieve Token from local Storage
      let accessToken = (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).getItem("a-at");
      //If we have no access token, No use to proceed
      if (!accessToken) return localStorage.removeItem("isPersistent");
      try {

        let decoded = jwt_decode(accessToken);
        //@ts-ignore
        state.userId = decoded.user_id;
        state.accessToken = accessToken;

        //@ts-ignore
        state.userInitials = decoded.ui;
        //@ts-ignore
        state.profilePicture = decoded.pp;
        //@ts-ignore
        state.firstName = decoded.fn;
        //@ts-ignore
        state.userRoles = decoded.roles;
        //@ts-ignore
        state.isPesamoniUser = decoded.isP;

        //@ts-ignore
        state.fullName = decoded.fn;

        //@ts-ignore

      } catch (error) {
        //Nothing to do
        //TODO: HANDLE THIS SCENARIO
      }
    },
    [SAVE_SEARCH_RESULTS]: (state, payload) => {
      state.search_results = payload;
    }
  },

  getters: {
    isLoggedIn: state => {
      return !!state.userId;
    },

    isQualityAssurance: state => {
      //@ts-ignore
      return state.userRoles.includes(role.QA);
    },

    canViewElections: state => {
      return state.userRoles.some(roleItem => {
        return [role.AR, role.IT, role.VC, role.DEAN, role.SUPER_ADMIN, role.DEAN_OF_STUDENTS].indexOf(roleItem) > -1;
      });
    },

    canViewExaminations: state => {
      return state.userRoles.some(roleItem => {
        return state.examination_role.indexOf(roleItem) > -1;
      });
    },

    canViewCourseWork: state => {
      return state.userRoles.some(roleItem => {
        return state.view_course_work.indexOf(roleItem) > -1;
      });
    },

    canViewResitsAndRetakes: state => {
      return state.userRoles.some(roleItem => {
        return state.view_resits_and_retakes.indexOf(roleItem) > -1;
      });
    },

    canCUDCourseWork: state => {
      return state.userRoles.some(roleItem => {
        return state.course_work_CUD.indexOf(roleItem) > -1;
      });
    },

    canViewGrading: state => {
      return state.userRoles.some(roleItem => {
        return state.view_grading.indexOf(roleItem) > -1;
      });
    },


    canStaffCrud: state => {
      return state.userRoles.some(roleItem => {
        return [role.HR, role.VC, role.SUPER_ADMIN].indexOf(roleItem) > -1;
      });
    },

    canViewStudents: state => {
      return state.userRoles.some(roleItem => {
        return state.student_read.indexOf(roleItem) > -1;
      });
    },

    canStudentsCrud: state => {
      return state.userRoles.some(roleItem => {
        return state.student_crud.indexOf(roleItem) > -1;
      });
    },

    canApproveOrRejectMarkSheet: state => {
      return state.userRoles.some(roleItem => {
        return state.can_approve_or_reject_mark_sheet.indexOf(roleItem) > -1;
      });
    },

    canApproveOrRejectResultSheet: state => {
      return state.userRoles.some((roleItem) => {
        return state.can_approve_or_reject_result_sheet.indexOf(roleItem) > -1;
      });
    },

    canPublishResultSheet: state => {
      return state.userRoles.some((roleItem) => {
        return state.can_publish_result_sheet.indexOf(roleItem) > -1;
      });
    },

    canDeleteStudent: state => {
      return state.userRoles.some(roleItem => {
        return state.delete_student.indexOf(roleItem) > -1;
      });
    },

    canActivateStudent: state => {
      return state.userRoles.some(roleItem => {
        return state.activate_student.indexOf(roleItem) > -1;
      });
    },

    canGrantAccessCrud: state => {
      return state.userRoles.some(roleItem => {
        return state.grant_access.indexOf(roleItem) > -1;
      });
    },

    canViewStaff: state => {
      return state.userRoles.some(roleItem => {
        return state.staff_read.indexOf(roleItem) > -1;
      });
    },

    isCafeteriaAdmin: state => {
      return state.userRoles.some(roleItem => {
        return state.cafeteria_admin.indexOf(roleItem) > -1;
      });
    },

    canAssignStaff: state => {
      return state.userRoles.some(roleItem => {
        return state.assign_staff.indexOf(roleItem) > -1;
      });
    },

    canBatchCrud: state => {
      return state.userRoles.some(roleItem => {
        return state.create_batch_read.indexOf(roleItem) > -1;
      });
    },

    canViewLiveLectures: state => {
      return state.userRoles.some(roleItem => {
        return state.live_lectures_read.indexOf(roleItem) > -1;
      });
    },

    canViewResources: state => {
      return state.userRoles.some(roleItem => {
        return state.resource_content.indexOf(roleItem) > -1;
      });
    },

    canResetPassword: state => {
      return state.userRoles.some(roleItem => {
        return [role.IT, role.SUPER_ADMIN].indexOf(roleItem) > -1;
      });
    },

    canViewHelpMessages: state => {
      return state.userRoles.some(roleItem => {
        return state.help_messages.indexOf(roleItem) > -1;
      });
    },

    canViewUserRequests: state => {
      //TODO: ADD USERS FOR USER REQUESTS
      return state.userRoles.some(roleItem => {
        return [role.AR, role.SUPER_ADMIN, role.VC, role.IT, role.QA, role.HR].indexOf(roleItem) > -1;
      });
    },

    canViewNoticeBoardCRUD: state => {
      //TODO: ADD USERS FOR USER REQUESTS
      return state.userRoles.some(roleItem => {
        return [role.AR, role.SUPER_ADMIN, role.VC, role.IT, role.QA, role.HR].indexOf(roleItem) > -1;
      });
    },

    canFacultiesToCourseUnitsCRUD: state => {
      //TODO: ADD USERS FOR USER REQUESTS
      return state.userRoles.some(roleItem => {
        return state.faculties_cUnits_read.indexOf(roleItem) > -1;
      });
    },

    canFacultiesToCourseUnitsCRUDOnly: state => {
      //TODO: ADD USERS FOR USER REQUESTSfaculties_cUnits_CRUD
      return state.userRoles.some(roleItem => {
        return state.faculties_cUnits_CRUD.indexOf(roleItem) > -1;
      });
    },

    canViewTopics: state => {
      //TODO: ADD USERS FOR USER REQUESTS
      return state.userRoles.some(roleItem => {
        return state.view_topics.indexOf(roleItem) > -1;
      });
    },

    canApproveStaff: state => {
      //TODO: ADD USERS FOR USER REQUESTS
      return state.userRoles.some(roleItem => {
        return state.approve_staff.indexOf(roleItem) > -1;
      });
    },

    canExamCRUD: state => {
      //TODO: ADD USERS FOR USER REQUESTS
      return state.userRoles.some(roleItem => {
        // console.log(state.examinations)
        return state.examinations.indexOf(roleItem) > -1;
      }) || state.isPesamoniUser;
    },

    canViewStudentApplications: state => {
      //TODO: ADD USERS FOR USER REQUESTS
      return state.userRoles.some(roleItem => {
        return state.student_applications.indexOf(roleItem) > -1;
      }) || state.isPesamoniUser;
    },

    canViewFinancialReports: state => {
      return state.userRoles.some(roleItem => {
        return state.view_financial_reports.indexOf(roleItem) > -1;
      }) || state.isPesamoniUser;
    },

    canViewConfigurations: state => {
      //TODO: ADD USERS FOR USER REQUESTS
      return state.userRoles.some(roleItem => {
        return state.configurations.indexOf(roleItem) > -1;
      }) || state.isPesamoniUser;
    },

    canViewPayments: state => {
      //TODO: ADD USERS FOR USER REQUESTS
      return state.userRoles.some(roleItem => {
        return state.payments_read.indexOf(roleItem) > -1;
      }) || state.isPesamoniUser;
    },

    canAdmitStudent: state => {
      //TODO: ADD USERS FOR USER REQUESTS
      return state.userRoles.some(roleItem => {
        return state.admit_student.indexOf(roleItem) > -1;
      }) || state.isPesamoniUser;
    },

    canManageElections: state => {
      return state.userRoles.some(roleItem => {
        return [role.VC, role.DEAN_OF_STUDENTS, role.SUPER_ADMIN].indexOf(roleItem) > -1;
      }) || state.isPesamoniUser;
    },

    canViewProgrammeChangeRequests: state => {
      return state.userRoles.some(roleItem => {
        return state.view_programme_change_requests.indexOf(roleItem) > -1;
      }) || state.isPesamoniUser;
    },

    canViewIntakeChangeRequests: state => {
      return state.userRoles.some(roleItem => {
        return state.view_intake_change_requests.indexOf(roleItem) > -1;
      }) || state.isPesamoniUser;
    },

    canViewSessionChangeRequests: state => {
      return state.userRoles.some(roleItem => {
        return state.view_session_change_requests.indexOf(roleItem) > -1;
      }) || state.isPesamoniUser;
    },

  },

  plugins: [elearningStore],
  actions: {
  },
  modules: {
  }
})