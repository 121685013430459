<template>
  <div class="dashboard">
    <div style="z-index: 100 !important">
      <!---THE SideNavbar component--->
      <SideNavbarComponent></SideNavbarComponent>
    </div>

    <div :class="{noPadding :  $route.name == 'chat-inquiries' }">
      <!---THE Navbar component--->
      <div
        id="navbar-section"
        style="z-index: 100"
      >
        <Navbar></Navbar>
      </div>
      <div
        id="body-view-section"
        style="z-index: 99"
      >
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/navbar-component.vue";
import SideNavbarComponent from "../components/side-navbar-component.vue";
import firebase from "firebase/app";
import "firebase/firestore";

export default {
  components: {
    Navbar,
    SideNavbarComponent,
  },
  data() {
    return {
      unsubscribe: () => {},
    };
  },

  methods: {
    async getUnReadMessagesCount() {
      try {
        const response = await this.$http.get(
          `chats/unread-conversations-count`
        );
        if (
          response.data.success &&
          response.data.message ===
            "UNREAD CONVERSATION COUNT FETCHED SUCCESSFULLY"
        ) {
          const unReadConversationsCount =
            response.data.unread_conversation_count;
          //commit the unread messages to the store
          this.$store.commit("SET_UNREAD_MESSAGES_COUNT", unReadConversationsCount);
        }
      } catch (error) {
        // console.log("failed to get unread messages");
      }
    },
    async listenForPushNotifications() {
      // Let's check if the browser supports notifications
      if (!("Notification" in window)) {
        return this.$alert(
          "The Browser you are using does not support Desktop Notifications. For Better Experience, please use upgrade your Browser.",
          "Outdated Browser",
          {
            confirmButtonText: "OK",
            type: "warning",
            callback: () => {},
          }
        );
        // return this.showWarningMessage();
      } else if (Notification.permission === "denied") {
        return this.showWarningMessage(
          "Notifications Turned Off",
          "Notifications are turned Off on your Browser. Please go to Site Settings and enable Notifications for better experience"
        );
      }

      try {
        const messaging = firebase.messaging();
        // messaging.
        let token = await messaging.getToken({
          vapidKey:
            "BPmT1lBjD07dWnmtZozTSwNKsZ71St1ZXk3-yaWoIM3xBbtuFN1MKJlEc6Bt2qSxvTKrKwX32d41URjQDmv8CZA",
        });
        if (token) {
          try {
            //Send Token to DB to add it if it doesnt exist already
            if (this.$store.getters.isLoggedIn) {
              await this.$http.post("push-notifications-device-tokens/save", {
                deviceToken: token,
              });
            }
          } catch {
            //Ignore such errors for now
          }
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unable to Get Device Token from Firebase yet user gave permissions"
          );
        }
      } catch (error) {
        //
        if (Notification.permission === "denied") {
          return this.showWarningMessage(
            "Notifications Turned Off",
            "Notifications are turned Off on your Browser. Please go to Site Settings and enable Notifications for better experience"
          );
        }
      }
    },

    listenForDeviceTokenChanges() {
      //This helps prefix dev tag to the ID for firebase to avoid logging out people accidentally
      const idPrefixer =
        process.env.NODE_ENV == "production" && !process.env.VUE_APP_IS_STAGING
          ? ""
          : "dev_";
      this.unsubscribe = firebase
        .firestore()
        .doc(
          `users/deviceTokens/staff/${idPrefixer}${this.$store.state.userId}`
        )
        .onSnapshot({ includeMetadataChanges: false }, (docSnapshot) => {
          if (docSnapshot.metadata.fromCache) return;
          if (
            !docSnapshot.exists ||
            docSnapshot.data().deviceToken !== this.$store.state.deviceToken
          ) {
            // console.log("FB", docSnapshot.data()?.deviceToken)
            // console.log("dev", this.$store.state.deviceToken)
            //@ts-ignore
            //Clear Axio Default Headers
            this.axios.defaults.headers.common["Authorization"] = "";

            this.$store.commit("LOGOUT_USER");

            this.showSuccessMessage(
              !docSnapshot.exists ? "Logout Successful" : "Logged in elsewhere",
              !docSnapshot.exists
                ? "You were logged out successfully"
                : "You were logged in on another Device"
            );
            return this.$router.replace({ path: "/login" });
          }
        });
    },
  },

  destroyed() {
    this.unsubscribe();
  },

  mounted() {
    this.listenForPushNotifications();
    this.listenForDeviceTokenChanges();
    this.getUnReadMessagesCount();
  },
};
</script>

<style scoped>
.noPadding {
  padding-top: 0px !important ;
  padding-bottom: 0px !important;
  overflow-y: hidden;
}
.dashboard {
  position: relative;
}

.dashboard > div:first-child {
  width: 20%;
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  min-height: 100vh;
  padding: 30px 0px;
  overflow-y: scroll;
}

.dashboard > div:nth-child(2) {
  width: 80%;
  margin-left: 20%;
  background-color: #f6f6f6;
  min-height: 100vh;
  padding-top: 66px;
  padding-bottom: 50px;
}

.dashboard > div:nth-child(2) #navbar-section {
  background-color: #f6f6f6;
  position: fixed;
  top: 0px;
  right: 0px;
  width: 80%;
  margin: 0px;
}

#body-view-section {
  min-height: 100vh;
  width: 100%;
}

/* width */
.dashboard > div:first-child::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.dashboard > div:first-child::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  border-radius: 10px;
  background-color: #f6f6f6;
}

/* Handle */
.dashboard > div:first-child::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.dashboard > div:first-child::-webkit-scrollbar-thumb:hover {
  background: #1f4ea1;
  border-radius: 10px;
}

/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f6f6f6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1f4ea1;
  border-radius: 10px;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  .dashboard > div:first-child {
    width: 0%;
    position: static;
    display: none;
  }

  .dashboard > div:nth-child(2) {
    width: 100%;
    margin-left: 0%;
    background-color: #f6f6f6;
    min-height: 100vh;
    padding-bottom: 50px;
  }

  .dashboard > div:nth-child(2) > div {
    position: static;
    top: 0px;
    right: 0px;
    width: 100%;
  }

  .dashboard > div:nth-child(2) #navbar-section {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .dashboard > div:first-child {
    width: 0%;
    position: static;
    display: none;
  }

  .dashboard > div:nth-child(2) {
    width: 100%;
    margin-left: 0%;
    background-color: #f6f6f6;
    min-height: 100vh;
    padding-bottom: 50px;
  }
  .dashboard > div:nth-child(2) > div {
    position: static;
    top: 0px;
    right: 0px;
    width: 100%;
  }
  .dashboard > div:nth-child(2) #navbar-section {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .dashboard > div:first-child {
    width: 0%;
    position: static;
    display: none;
  }

  .dashboard > div:nth-child(2) {
    width: 100%;
    margin-left: 0%;
    background-color: #f6f6f6;
    min-height: 100vh;
    padding-bottom: 50px;
  }
  .dashboard > div:nth-child(2) > div {
    position: static;
    top: 0px;
    right: 0px;
    width: 100%;
  }
  .dashboard > div:nth-child(2) #navbar-section {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .dashboard > div:first-child {
    width: 0%;
    position: static;
    display: none;
  }

  .dashboard > div:nth-child(2) {
    width: 100%;
    margin-left: 0%;
    background-color: #f6f6f6;
    min-height: 100vh;
    padding-bottom: 50px;
  }
  .dashboard > div:nth-child(2) #navbar-section {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
  }
}
</style>

