<template>
  <section>
    <div id="sidebar-article">
      <div style="cursor: pointer">
        <div
          style="
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <img
            :src="$store.state.logoImageUrl"
            style="height: 65px"
            alt=""
            class="img-fluid"
          />
        </div>
      </div>

      <div class="theLists">
        <ul style="margin-bottom: 0">
          <li v-on:click="closeNav()">
            <router-link to="/dashboard/home">
              <div class="side-link" :class="{ active: $route.name == 'Home' }">
                <div><i class="fas fa-home text-truncate"></i> Home</div>
              </div>
            </router-link>
          </li>
        </ul>

        <el-collapse
          v-model="activeName"
          accordion
          class="side-nav-accordion"
          style="background: none"
        >
          <el-collapse-item name="1">
            <template slot="title">
              <div class="text-truncate">
                <i class="header-icon el-icon-notebook-2 mr-1"></i>
                Academics
              </div>
            </template>
            <ul>
              <li
                v-on:click="closeNav()"
                :class="
                  $store.getters.canFacultiesToCourseUnitsCRUD
                    ? 'show_list'
                    : 'hide_list'
                "
              >
                <router-link to="/dashboard/faculties">
                  <div
                    class="side-link collapsable-side-bar-item"
                    :class="{
                      active:
                        $route.name == 'Faculties' ||
                        $route.name == 'FacultyDetail' ||
                        $route.name == 'DepartmentCourses' ||
                        $route.name == 'MyCourseUnits',
                    }"
                  >
                    <div class="text-truncate">
                      <i class="fas fa-th"></i> Faculties
                    </div>
                  </div>
                </router-link>
              </li>

              <li
                v-on:click="closeNav()"
                :class="$store.getters.canBatchCrud ? 'show_list' : 'hide_list'"
              >
                <router-link to="/dashboard/intake-batches">
                  <div
                    class="side-link collapsable-side-bar-item"
                    :class="{ active: $route.name == 'IntakeBatches' }"
                  >
                    <div class="text-truncate">
                      <i class="fas fa-window-restore"></i> Intakes
                    </div>
                  </div>
                </router-link>
              </li>

              <li
                v-on:click="closeNav()"
                :class="
                  $store.getters.canViewLiveLectures ? 'show_list' : 'hide_list'
                "
              >
                <router-link to="/dashboard/live-lectures">
                  <div
                    class="side-link collapsable-side-bar-item"
                    :class="{
                      active:
                        $route.name == 'LiveLecture' ||
                        $route.name == 'LiveLectures',
                    }"
                  >
                    <div class="text-truncate">
                      <i class="fas fa-video"></i> Live Lectures
                    </div>
                  </div>
                </router-link>
              </li>

              <li v-on:click="closeNav()">
                <router-link to="/dashboard/students">
                  <div
                    class="side-link collapsable-side-bar-item"
                    :class="{ active: $route.name == 'Students' }"
                  >
                    <div class="text-truncate">
                      <i class="fas fa-user-friends"></i> Students
                    </div>
                  </div>
                </router-link>
              </li>

              <li
                v-on:click="closeNav()"
                :class="
                  $store.getters.canViewStudentApplications
                    ? 'show_list'
                    : 'hide_list'
                "
              >
                <router-link to="/dashboard/student-application">
                  <div
                    class="side-link collapsable-side-bar-item"
                    :class="{ active: $route.name == 'StudentApplication' }"
                  >
                    <div class="text-truncate">
                      <i class="fas fa-address-card"></i>Student Applications
                    </div>
                  </div>
                </router-link>
              </li>

              <li
                v-on:click="closeNav()"
                :class="
                  $store.getters.canViewStudentApplications
                    ? 'show_list'
                    : 'hide_list'
                "
              >
                <router-link to="/dashboard/online-applications">
                  <div
                    class="side-link collapsable-side-bar-item"
                    :class="{ active: $route.name == 'OnlineApplications' }"
                  >
                    <div class="text-truncate">
                      <span
                        ><i class="el-icon-menu"></i>Website Applications</span
                      >
                    </div>
                  </div>
                </router-link>
              </li>

              <li
                v-on:click="closeNav()"
                :class="
                  $store.getters.canViewResources ? 'show_list' : 'hide_list'
                "
              >
                <router-link to="/dashboard/resources">
                  <div
                    class="side-link collapsable-side-bar-item"
                    :class="{ active: $route.name == 'Resources' }"
                  >
                    <div class="text-truncate">
                      <i class="fas fa-cubes"></i> Resources
                    </div>
                  </div>
                </router-link>
              </li>

              <li
                v-on:click="closeNav()"
                :class="
                  $store.getters.canViewTopics ? 'show_list' : 'hide_list'
                "
              >
                <router-link to="/dashboard/topics">
                  <div
                    class="side-link collapsable-side-bar-item"
                    :class="{ active: $route.name == 'Topics' }"
                  >
                    <span><i class="el-icon-reading"></i> Topics</span>
                  </div>
                </router-link>
              </li>
            </ul>
          </el-collapse-item>

          <el-collapse-item name="4">
            <template slot="title">
              <div class="text-truncate">
                <i class="header-icon el-icon-finished mr-1"></i>
                Assessments
              </div>
            </template>
            <ul>
              <li
                v-on:click="closeNav()"
                :class="
                  $store.getters.canViewExaminations ? 'show_list' : 'hide_list'
                "
              >
                <router-link to="/dashboard/examination-sets">
                  <div
                    class="side-link collapsable-side-bar-item"
                    :class="{
                      active:
                        $route.name == 'ExaminationSets' ||
                        $route.name == 'Examinations' ||
                        $route.name == 'AddExamination' ||
                        $route.name == 'EditExamination' ||
                        $route.name == 'ExamDetails' ||
                        $route.name == 'AnswerSheet' ||
                        $route.name == 'ExamStatistics' ||
                        $route.name == 'AnswerSheet' ||
                        $route.name == 'ExamSubmissions',
                    }"
                  >
                    <div class="text-truncate">
                      <i class="el-icon-edit-outline"></i> Examinations
                    </div>
                  </div>
                </router-link>
              </li>

              <li
                v-on:click="closeNav()"
                :class="
                  $store.getters.canViewGrading ? 'show_list' : 'hide_list'
                "
              >
                <router-link to="/dashboard/grading">
                  <div
                    class="side-link collapsable-side-bar-item"
                    :class="{ active: $route.name == 'Grading' }"
                  >
                    <div class="text-truncate">
                      <i class="fas fa-line-chart"></i> Grading
                    </div>
                  </div>
                </router-link>
              </li>

              <li
                v-on:click="closeNav()"
                :class="
                  $store.getters.canViewCourseWork ? 'show_list' : 'hide_list'
                "
              >
                <router-link to="/dashboard/course-work-sets">
                  <div
                    class="side-link collapsable-side-bar-item"
                    :class="{
                      active:
                        $route.name == 'courseWork' ||
                        $route.name == 'CourseWorkSets' ||
                        $route.name == 'EditCourseWork' ||
                        $route.name == 'CourseWorkDetails',
                    }"
                  >
                    <div class="text-truncate">
                      <i class="el-icon-edit-outline"></i> Course Work
                    </div>
                  </div>
                </router-link>
              </li>

              <li
                v-on:click="closeNav()"
                :class="
                  $store.getters.canViewResitsAndRetakes
                    ? 'show_list'
                    : 'hide_list'
                "
              >
                <router-link to="/dashboard/resit-applications">
                  <div
                    class="side-link collapsable-side-bar-item"
                    :class="{ active: $route.name == 'ResitApplication' }"
                  >
                    <div class="text-truncate">
                      <i class="el-icon-notebook-2"></i> Resit Applications
                    </div>
                  </div>
                </router-link>
              </li>

              <li
                v-on:click="closeNav()"
                :class="
                  $store.getters.canViewResitsAndRetakes
                    ? 'show_list'
                    : 'hide_list'
                "
              >
                <router-link to="/dashboard/retake-applications">
                  <div
                    class="side-link collapsable-side-bar-item"
                    :class="{ active: $route.name == 'RetakeApplication' }"
                  >
                    <div class="text-truncate">
                      <i class="el-icon-refresh-left"></i> Retake Applications
                    </div>
                  </div>
                </router-link>
              </li>

              <li v-on:click="closeNav()">
                <router-link to="/dashboard/graduation-center">
                  <div
                    class="side-link collapsable-side-bar-item"
                    :class="{ active: $route.name == 'GraduationCenter.' }"
                  >
                    <div class="text-truncate">
                      <i class="fas fa-graduation-cap"></i> Graduation Center
                    </div>
                  </div>
                </router-link>
              </li>
            </ul>
          </el-collapse-item>

          <el-collapse-item name="2">
            <template slot="title">
              <div class="text-truncate">
                <i class="header-icon el-icon-user mr-1"></i>
                Staff Management
              </div>
            </template>
            <ul>
              <li
                v-on:click="closeNav()"
                :class="$store.getters.canViewStaff ? 'show_list' : 'hide_list'"
              >
                <router-link to="/dashboard/staff">
                  <div
                    class="side-link collapsable-side-bar-item"
                    :class="{ active: $route.name == 'Staff' }"
                  >
                    <div class="text-truncate">
                      <i class="fas fa-users"></i> Staff Members
                    </div>
                  </div>
                </router-link>
              </li>
              <li
                v-on:click="closeNav()"
                :class="
                  $store.getters.isCafeteriaAdmin ? 'show_list' : 'hide_list'
                "
              >
                <router-link to="/dashboard/cafeteria-management">
                  <div
                    class="side-link collapsable-side-bar-item"
                    :class="{ active: $route.name == 'CafeteriaManagement' }"
                  >
                    <div class="text-truncate">
                      <i class="el-icon-food"></i> Cafeteria Management
                    </div>
                  </div>
                </router-link>
              </li>
            </ul>
          </el-collapse-item>

          <el-collapse-item
            name="3"
            :class="$store.getters.canViewPayments ? 'show_list' : 'hide_list'"
          >
            <template slot="title">
              <div class="text-truncate">
                <i class="header-icon el-icon-money mr-1"></i>
                Finances
              </div>
            </template>
            <ul>
              <li
                v-on:click="closeNav()"
                :class="
                  $store.getters.canViewPayments ? 'show_list' : 'hide_list'
                "
              >
                <router-link to="/dashboard/billing-systems">
                  <div
                    class="side-link collapsable-side-bar-item"
                    :class="{
                      active: $route.name == 'BillingSystems',
                    }"
                  >
                    <div class="text-truncate">
                      <i class="el-icon-money"></i> Billing Systems
                    </div>
                  </div>
                </router-link>
              </li>

              <li
                v-on:click="closeNav()"
                :class="
                  $store.getters.canViewPayments ? 'show_list' : 'hide_list'
                "
              >
                <router-link to="/dashboard/billing-items">
                  <div
                    class="side-link collapsable-side-bar-item"
                    :class="{
                      active: $route.name == 'BillingItems',
                    }"
                  >
                    <div class="text-truncate">
                      <i class="el-icon-money"></i> Billing Items
                    </div>
                  </div>
                </router-link>
              </li>

              <li
                v-on:click="closeNav()"
                :class="
                  $store.getters.canViewPayments ? 'show_list' : 'hide_list'
                "
              >
                <router-link to="/dashboard/payments">
                  <div
                    class="side-link collapsable-side-bar-item"
                    :class="{ active: $route.name == 'Payments' }"
                  >
                    <div class="text-truncate">
                      <i class="el-icon-coin"></i> Payments
                    </div>
                  </div>
                </router-link>
              </li>

              <li
                v-on:click="closeNav()"
                :class="
                  $store.getters.canViewPayments ? 'show_list' : 'hide_list'
                "
              >
                <router-link to="/dashboard/payments-pending-confirmation">
                  <div
                    class="side-link collapsable-side-bar-item"
                    :class="{
                      active: $route.name == 'PaymentsPendingConfirmation',
                    }"
                  >
                    <div class="text-truncate">
                      <i class="el-icon-warning-outline"></i> Pending
                      Confirmation
                    </div>
                  </div>
                </router-link>
              </li>

              <li
                v-on:click="closeNav()"
                :class="
                  $store.getters.canViewPayments ? 'show_list' : 'hide_list'
                "
              >
                <router-link to="/dashboard/website-payments">
                  <div
                    class="side-link collapsable-side-bar-item"
                    :class="{ active: $route.name == 'OnlinePayments' }"
                  >
                    <div class="text-truncate">
                      <i class="el-icon-cloudy"></i>Website Payments
                    </div>
                  </div>
                </router-link>
              </li>

              <li
                v-on:click="closeNav()"
                :class="
                  $store.getters.canViewPayments ? 'show_list' : 'hide_list'
                "
              >
                <router-link to="/dashboard/scholarships">
                  <div
                    class="side-link collapsable-side-bar-item"
                    :class="{ active: $route.name == 'Scholarships' }"
                  >
                    <div class="text-truncate">
                      <i class="el-icon-coin"></i>Scholarships
                    </div>
                  </div>
                </router-link>
              </li>

              <li
                v-on:click="closeNav()"
                :class="
                  $store.getters.canViewFinancialReports
                    ? 'show_list'
                    : 'hide_list'
                "
              >
                <router-link to="/dashboard/financial-reports">
                  <div
                    class="side-link collapsable-side-bar-item"
                    :class="{ active: $route.name == 'FinancialReports' }"
                  >
                    <div class="text-truncate">
                      <i class="el-icon-notebook-2"></i>Financial Reports
                    </div>
                  </div>
                </router-link>
              </li>
            </ul>
          </el-collapse-item>

          <el-collapse-item name="5">
            <template slot="title">
              <div class="text-truncate">
                <i class="header-icon el-icon-edit-outline mr-1"></i>
                Student Change Requests
              </div>
            </template>
            <ul>
              <li
                v-on:click="closeNav()"
                :class="
                  $store.getters.canViewProgrammeChangeRequests
                    ? 'show_list'
                    : 'hide_list'
                "
              >
                <router-link to="/dashboard/programme-change-requests">
                  <div
                    class="side-link"
                    :class="{
                      active: $route.name == 'ProgrammeChangeRequests',
                    }"
                  >
                    <div class="text-truncate">
                      <i class="el-icon-document"></i> Program Change Requests
                    </div>
                  </div>
                </router-link>
              </li>
              <li
                v-on:click="closeNav()"
                :class="
                  $store.getters.canViewIntakeChangeRequests
                    ? 'show_list'
                    : 'hide_list'
                "
              >
                <router-link to="/dashboard/intake-change-requests">
                  <div
                    class="side-link"
                    :class="{
                      active: $route.name == 'StudentIntakeChangeRequests',
                    }"
                  >
                    <div class="text-truncate">
                      <i class="el-icon-document"></i> Intake Change Requests
                    </div>
                  </div>
                </router-link>
              </li>
              <li
                v-on:click="closeNav()"
                :class="
                  $store.getters.canViewSessionChangeRequests
                    ? 'show_list'
                    : 'hide_list'
                "
              >
                <router-link to="/dashboard/session-change-requests">
                  <div
                    class="side-link"
                    :class="{ active: $route.name == 'SessionChangeRequests' }"
                  >
                    <div class="text-truncate">
                      <i class="el-icon-date"></i> Session Change Requests
                    </div>
                  </div>
                </router-link>
              </li>
              <!-- <li v-on:click="closeNav()">
                <router-link to="/dashboard/credit-transfer">
                  <div class="side-link" :class="{ active: $route.name == '' }">
                    <div class="text-truncate">
                      <i class="fas fa-credit-card"></i> Exemption / Credit
                      Transfer
                    </div>
                  </div>
                </router-link>
              </li>
              <li v-on:click="closeNav()">
                <router-link to="/dashboard/dead-year">
                  <div class="side-link" :class="{ active: $route.name == '' }">
                    <div class="text-truncate">
                      <i class="fas fa-ban"></i> Dead Trimester / Year
                    </div>
                  </div>
                </router-link>
              </li>
              <li v-on:click="closeNav()">
                <router-link to="/dashboard/resumption-requests">
                  <div class="side-link" :class="{ active: $route.name == '' }">
                    <div class="text-truncate">
                      <i class="fas fa-undo"></i> Resumption Requests
                    </div>
                  </div>
                </router-link>
              </li> -->
            </ul>
          </el-collapse-item>
        </el-collapse>

        <ul style="">
          <li
            v-on:click="closeNav()"
            :class="
              $store.getters.canManageElections ? 'show_list' : 'hide_list'
            "
          >
            <router-link to="/dashboard/manage-elections">
              <div
                class="side-link"
                :class="{ active: $route.name == 'ManageElections' }"
              >
                <span
                  ><i class="el-icon-takeaway-box" style="font-weight: 600"></i>
                  Manage Elections</span
                >
              </div>
            </router-link>
          </li>

          <li v-if="shouldShowElections" v-on:click="closeNav()">
            <router-link to="/dashboard/elections/intro">
              <div
                class="side-link"
                :class="{
                  active:
                    $route.name == 'Elections' || $route.name == 'Elections',
                }"
              >
                <span><i class="el-icon-circle-check"></i> VU Elections</span>
              </div>
            </router-link>
          </li>

          <li
            v-on:click="closeNav()"
            :class="
              $store.getters.canViewHelpMessages ? 'show_list' : 'hide_list'
            "
          >
            <router-link to="/dashboard/chat-inquiries">
              <div
                class="side-link"
                :class="{ active: $route.name == 'HelpMessages' }"
              >
                <span
                  ><i
                    class="el-icon-chat-line-square"
                    style="font-weight: 600"
                  ></i>
                  Chat / Inquiries</span
                >
                <span
                  class="badge badge-light ml-2 rounded-circle p-1"
                  style="background-color: red"
                  v-if="this.$store.state.unReadMessagesCount > 0"
                  >{{ this.$store.state.unReadMessagesCount }}</span
                >
              </div>
            </router-link>
          </li>

          <li
            v-on:click="closeNav()"
            :class="
              $store.getters.canViewHelpMessages ? 'show_list' : 'hide_list'
            "
          >
            <router-link to="/dashboard/cafeteria">
              <div
                class="side-link"
                :class="{ active: $route.name == 'Cafeteria' }"
              >
                <span
                  ><i class="el-icon-food" style="font-weight: 600"></i>
                  Cafeteria</span
                >
              </div>
            </router-link>
          </li>
        </ul>
      </div>

      <div style="">
        <ul style="">
          <li v-on:click="closeNav()">
            <router-link to="/dashboard/settings">
              <div
                class="side-link"
                :class="{ active: $route.name == 'Settings' }"
              >
                <span><i class="fas el-icon-setting"></i> Settings</span>
              </div>
            </router-link>
          </li>
          <li
            v-on:click="closeNav()"
            :class="
              $store.getters.canViewConfigurations ? 'show_list' : 'hide_list'
            "
          >
            <el-collapse
              v-model="activeConfig"
              accordion
              class="side-nav-accordion"
              style="background: none"
            >
              <el-collapse-item name="1">
                <template slot="title">
                  <div class="text-truncate">
                    <i class="header-icon el-icon-setting mr-1"></i>
                    Configurations
                  </div>
                </template>
                <ul>
                  <li
                    v-on:click="closeNav()"
                    :class="
                      $store.getters.canViewConfigurations
                        ? 'show_list'
                        : 'hide_list'
                    "
                  >
                    <router-link to="/dashboard/configurations">
                      <div
                        class="side-link collapsable-side-bar-item"
                        :class="{ active: $route.name == 'Configurations' }"
                      >
                        <span
                          ><i class="el-icon-setting"></i> General
                          Configuration</span
                        >
                      </div>
                    </router-link>
                  </li>
                  <li
                    v-on:click="closeNav()"
                    :class="
                      $store.getters.canViewConfigurations
                        ? 'show_list'
                        : 'hide_list'
                    "
                  >
                    <router-link to="/dashboard/academic-year-configurations">
                      <div
                        class="side-link collapsable-side-bar-item"
                        :class="{
                          active: $route.name == 'AcademicYearConfigurations',
                        }"
                      >
                        <span
                          ><i class="el-icon-notebook-1"></i> Academic Year
                          config</span
                        >
                      </div>
                    </router-link>
                  </li>
                  <li
                    v-on:click="closeNav()"
                    :class="
                      $store.getters.canViewConfigurations
                        ? 'show_list'
                        : 'hide_list'
                    "
                  >
                    <router-link to="/dashboard/trimester-management">
                      <div
                        class="side-link collapsable-side-bar-item"
                        :class="{
                          active: $route.name == 'TrimesterManagement',
                        }"
                      >
                        <span
                          ><i class="el-icon-setting"></i> Trimester
                          Management</span
                        >
                      </div>
                    </router-link>
                  </li>
                </ul>
              </el-collapse-item>
            </el-collapse>
          </li>
          <!-- </li>
          <li v-on:click="closeNav()">
            <router-link to="/dashboard/help">
              <div
                class="side-link"
                :class="{ active: $route.name == 'Help' }"
              >
                <span><i class="far fa-question-circle"></i> Help</span>
              </div>
            </router-link>
          </li> -->
          <li @click="onLogoutIntent">
            <div class="side-link">
              <span><i class="fas fa-sign-out-alt"></i> Logout</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      help_messages_count: "",
      user_requests_count: "",
      activeName: "",
      activeConfig: "",
      activeStudentChange: "",
    };
  },

  computed: {
    shouldShowElections() {
      return (
        this.$store.state.isPesamoniUser || this.$store.getters.canViewElections
      );
    },
  },

  mounted() {
    this.messages_count();
  },

  methods: {
    async messages_count() {
      try {
        this.loading = true;
        let request = await this.$http.get(
          `staff/count-help-messages-and-user-requests/${this.$store.state.userId}`
        );
        //   console.log(request);
        if (
          request.data.success &&
          request.data.message == "COUNTS_RETRIEVED_SUCCESSFULLY"
        ) {
          this.help_messages_count = request.data.help_messages;
          this.user_requests_count = request.data.user_requests;
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          "Unable to load Program categories",
          "An unexpected error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    closeNav() {
      document.getElementById("mySidenav").style.width = "0";
    },

    async onLogoutIntent() {
      this.closeNav();
      try {
        let logOut = await this.$confirm(
          "You will be immediately logged out of your account, Continue?",
          "Confirm Log out",
          {
            confirmButtonText: "Logout",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        );

        if (logOut) {
          this.logOut();
        }
      } catch {
        //no-empty
      }
    },

    async logOut() {
      let request = await this.httpRequest({
        url: "/staff/logout",
        method: "POST",
        showFullScreenLoader: true,
        fullScreenLoaderMessage: "Logging you out...",
      });
      if (
        request &&
        request.success &&
        request.message == "LOGOUT_SUCCESSFUL"
      ) {
        loading.close();
        this.$store.commit("LOGOUT_USER");
        this.$router.replace({ path: "/login" });
      }
    },
  },
};
</script>

<style>
.side-nav-accordion .el-collapse-item {
  margin-left: 2em;
}

.side-nav-accordion.el-collapse {
  border: 0 !important;
}

.side-nav-accordion .el-collapse-item__wrap {
  border: 0;
}

.side-nav-accordion .el-collapse-item__header {
  background-color: white !important;
  border: none !important;
}

.side-nav-accordion .el-collapse-item__content {
  background-color: white !important;
  border: none !important;
  font-size: 1em !important;
}
</style>

<style scoped>
.theLists {
  margin: 50px 0px;
  padding: 0px;
}

ul {
  padding: 0px;
}

ul li {
  list-style: none;
  text-align: left;
  font-weight: 400;
}

ul li i {
  margin-right: 5px;
}

.side-link {
  color: rgba(0, 0, 0, 0.74);
  font-weight: 500;
  padding: 10px 30px;
  cursor: pointer;
  transition: 0.5s;
}

.side-link.active {
  border-left: 6px solid var(--vu-red);
  color: var(--el-app-primary) !important;
  font-weight: 600;
}

.side-link:hover {
  background-color: #eeeeee;
  padding: 10px 40px;
  transition: 0.5s;
  color: #74a4c5;
}

.collapsable-side-bar-item {
  padding-left: 5px !important;
}

.bg-white {
  background-color: white;
}
</style>
