<template>
  <el-dialog
    title="Students in the Intake"
    :visible="dialogVisible"
    width="80%"
    :destroy-on-close="true"
    :before-close="closeDialog"
    @open="onDialogOpen"
  >
    <div v-loading="isLoading">
      <div
        style="
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div class="row w-100 d-flex">
          <div class="col-md-5 mb-3">
            <el-input
              type="text"
              class="legacy-input border-color-fix"
              v-model="filters[0].value"
              placeholder="Search by name"
              spellcheck="off"
            />
          </div>
          <div class="col-md-5">
            <el-button
              style="background-color: var(--el-app-primary); color: white"
              class="z-depth-0 addbtn"
              v-ripple="'rgba(255, 255, 255, 0.35)'"
              @click="exportStudents"
              >Export To Excel</el-button
            >
          </div>
        </div>
      </div>

      <div style="width: 100%">
        <data-tables
          :data="students"
          :table-props="{
            border: true,
            stripe: true,
          }"
          :pagination-props="{ pageSizes: [10, 20, 30, 50, 100] }"
          type="expand"
          :filters="filters"
        >
          <el-table-column
            label="#"
            :show-overflow-tooltip="true"
            label-class-name="text-left"
            width="70px"
          >
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>

          <el-table-column
            label="Name"
            :show-overflow-tooltip="true"
            label-class-name="text-left"
            width="auto"
          >
            <template slot-scope="scope">
              <div style="display: flex; align-items: center">
                <div style="width: 40px; height: 40px; border-radius: 50%">
                  <div
                    v-if="scope.row.profile_picture"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      background-color: var(--el-app-primary);
                      color: white;
                      width: 40px;
                      height: 40px;
                      object-fit: cover;
                      border-radius: 100%;
                    "
                  >
                    <img
                      :src="scope.row.profile_picture"
                      class="img-fluid"
                      style="
                        width: 40px;
                        height: 40px;
                        object-fit: cover;
                        border-radius: 100%;
                      "
                    />
                  </div>
                  <div
                    v-if="scope.row.profile_picture == null"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      background-color: var(--el-app-primary);
                      color: white;
                      width: 40px;
                      height: 40px;
                      object-fit: cover;
                      border-radius: 100%;
                    "
                  >
                    <strong>{{ getInitials(getFullName(scope.row)) }}</strong>
                  </div>
                </div>
                <div style="margin-left: 10px">
                  <span>{{ getFullName(scope.row) }}</span
                  ><br />
                  <!-- <span showing years on edit style="font-weight: 600;">Email: </span><span>{{ scope.row.email }}</span> -->
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            label="Registration Number"
            :show-overflow-tooltip="true"
            label-class-name="text-left"
            width="auto"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.registration_no }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Phone Number">
            <template slot-scope="scope">
              <span>{{ scope.row.phone_number }}</span>
            </template>
          </el-table-column>
        </data-tables>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">Close</el-button>
    </span>
  </el-dialog>
</template>

<script>
import getStudentFullName from "@/helpers/getStudentFullName";
export default {
  props: ["dialogVisible", "batchId"],
  emits: ["closeDialog"],
  data() {
    return {
      students: [],
      isLoading: false,
      currentPage: 0,
      pageSize: 10,
      rules: {},
      filters: [
        {
          prop: "",
          value: "",
          filterFn: (row, filterItem) => {
            return this.getFullName(row)
              .toLowerCase()
              .includes(filterItem.value.toLowerCase().trim());
          },
        },
      ],
    };
  },

  computed: {
    getInitials() {
      return (nameString) => {
        //  format(parseISO(dateString), "do MMMM yyyy");
        var names = nameString.split(" "),
          initials = names[0].substring(0, 1).toUpperCase();
        if (names.length > 1) {
          initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      };
    },
    totalNumberOfStudents() {
      return this.students.length;
    },
  },

  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    onDialogOpen() {
      this.getStudents();
    },

    getFullName(student) {
      return getStudentFullName(student);
    },

    async getStudents() {
      this.isLoading = true;
      try {
        let request = await this.$http.get(
          `course-intake-batches/fetch-students/${this.batchId}`
        );
        if (request.data.success) {
          this.students = request.data.batch.batch_students;
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          "Unable to load students in this Intake",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.isLoading = false;
      }
    },

    async exportStudents() {
      try {
        this.isLoading = true;

        let request = await this.$http.get(
          `students/export-excel?courseIntakeBatchId=${this.batchId}`
        );
        if (
          request.data.success &&
          request.data.message == "STUDENT EXCEL SHEET GENERATED SUCCESSFULLY"
        ) {
          const link = document.createElement("a");
          link.href = request.data.link;
          link.click();
          return;
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }

        this.showFailedMessage(
          "Unable to export Students",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
