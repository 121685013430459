<template>
  <div>
    <article>
      <section>
        <div class="container">
          <div class="students_dialogs text-left">
            <el-dialog
              title="Student Details"
              :visible="isVisible"
              @open="getStudent()"
              :destroy-on-close="true"
              :before-close="handleClose"
            >
              <div v-loading="submitting">
                <div>
                  <div>
                    <p>
                      <span style="font-weight: 700">Name:</span>
                      {{ fullName }}
                    </p>
                    <p>
                      <span style="font-weight: 700">Email:</span>
                      {{ studentForm.email }}
                    </p>
                    <p>
                      <span style="font-weight: 700">Phone Number:</span>
                      {{ studentForm.phone_number }}
                    </p>
                    <p>
                      <span style="font-weight: 700">Registration Number:</span>
                      {{ studentForm.registration_no }}
                    </p>
                    <p>
                      <span style="font-weight: 700">Gender:</span>
                      {{ studentForm.gender }}
                    </p>

                    <div
                      style="
                        color: var(--el-app-primary);
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                      "
                    >
                      <div style="cursor: pointer">
                        <div @click="showPassword()" v-if="!showPasswordBool">
                          <span style="font-weight: 700"
                            >Show Password<i class="el-icon-key"></i
                          ></span>
                        </div>
                        <div v-else>
                          <span style="font-weight: 700">Password: </span
                          >{{ StudentPassword }}
                        </div>
                      </div>

                      <div
                        @click="resetPassword"
                        class="btn-success"
                        style="
                          cursor: pointer;
                          font-weight: 600;
                          border-radius: 5px;
                          padding: 3px 10px;
                        "
                      >
                        Reset Password
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div>
                    <el-switch
                      v-model="is_active"
                      :disabled="!$store.getters.canActivateStudent"
                      @change="switchStatus"
                      active-color="#13ce66"
                      inactive-color=""
                    >
                    </el-switch>
                    <label class="mt-2 pl-2" style="font-weight: 400"
                      >Toggle Student Active</label
                    >
                  </div>

                  <div class="">
                    <el-switch
                      v-model="is_modules_selection_disabled_by_retakes"
                      @change="switchModuleSelectionStatus"
                      active-color="#13ce66"
                      inactive-color=""
                    >
                    </el-switch>
                    <label class="mt-3 pl-2" style="font-weight: 400"
                      >Module Selection Blocked by many retakes</label
                    >
                  </div>
                  <hr />
                </div>

                <div>
                  <el-button
                    style="background-color: #9933cc; color: white"
                    @click="onOpenDetailedInfoDialog(studentId)"
                    icon="el-icon-view"
                    >Detailed Info</el-button
                  >
                  <el-button
                    type="primary"
                    @click="editStudent(studentId)"
                    icon="el-icon-edit"
                    >Edit</el-button
                  >
                  <el-tooltip content="Delete Student" placement="top">
                    <el-button
                      type="danger"
                      disabled
                      @click="deleteStudent"
                      icon="el-icon-delete"
                    >
                      Delete</el-button
                    >
                  </el-tooltip>
                </div>
              </div>
            </el-dialog>
          </div>
          <DetailedStudentInfoDialog
            :isVisible="showDetailedInfoDialog"
            :studentId="studentId"
            @close-dialog="onCloseDetailedInfoDialog"
          />
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import print from "vue-print-nb";
import DetailedStudentInfoDialog from "./detailed-student-info-dialog.vue";
import getStudentFullName from "@/helpers/getStudentFullName";
export default {
  directives: {
    print,
  },
  components: {
    DetailedStudentInfoDialog,
  },
  emits: ["re-fresh"],
  data() {
    return {
      studentDetailsModal: false,
      loading: true,
      loadingError: false,
      submitting: false,
      generateStudentIdModal: false,
      studentDetails: {},
      StudentPassword: "",
      showPasswordBool: false,
      is_active: false,
      is_modules_selection_disabled_by_retakes: false,
      fullName: "",
      studentForm: {
        registration_no: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        gender: "",
        nationality: "",
        session: "",
        printLoading: true,
      },
      AdmissionLetterDetails: {},
      showDetailedInfoDialog: false,
    };
  },

  props: {
    studentId: {
      type: String,
      required: true,
    },

    isVisible: {
      type: Boolean,
      required: true,
    },
  },

  // watch: {
  //   showStudentInfoDialog() {
  //     if (this.showStudentInfoDialog === true) {
  //       this.studentDetailsModal = true;
  //       this.getStudent();
  //     } else {
  //       this.studentDetailsModal = false;
  //     }
  //   },
  // },

  methods: {
    async showModal() {
      try {
        this.submitting = true;
        await this.getStudent();
      } catch (error) {
        this.submitting = true;
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again"
          );
        }
        this.showFailedMessage(
          "Unable to load Student",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.submitting = false;
      }
    },

    handleClose() {
      this.$emit("closeStudentInfoDialog");
    },

    async getAllStudents() {
      this.$emit("re-fresh");
    },

    async getStudent() {
      // eslint-disable-next-line no-useless-catch
      try {
        this.submitting = true;
        let request = await this.$http.get(
          `students/get-student-for-edit/${this.studentId}`
        );
        if (
          request.data.success &&
          request.data.message == "STUDENT_RETRIEVED_SUCCESSFULLY"
        ) {
          this.studentDetails = request.data.student;
          this.fullName = getStudentFullName(request.data.student);
          // this.studentForm.first_name + " " + this.studentForm.last_name;
          this.studentForm.email = request.data.student.email;
          this.studentForm.phone_number = request.data.student.phone_number;
          this.studentForm.registration_no =
            request.data.student.registration_no;
          this.studentForm.gender = request.data.student.gender;
          this.studentForm.nationality = request.data.student.nationality;
          this.studentForm.session = request.data.student.session;
          this.is_active = request.data.student.is_active;
          this.is_modules_selection_disabled_by_retakes =
            request.data.student.is_modules_selection_disabled_by_retakes;
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        //If the API returns other status codes besides 2xx, Handle these responses
        if (error.response) {
          if (error.response.data.message === "API MESSAGE HERE") {
            return;
          }
        } else {
          this.showFailedMessage(
            "Operation failed",
            "Unable to fetch student data, Please try again"
          );
        }
      } finally {
        this.submitting = false;
      }
    },

    async switchStatus() {
      let studentStatus = {
        is_active: this.is_active,
      };
      try {
        this.submitting = true;
        this.loading = true;
        this.loadingError = false;
        let request = await this.$http.patch(
          `students/change_active/${this.studentId}`,
          studentStatus
        );
        if (
          request.data.success &&
          request.data.message == "STUDENT_ACTIVE_STATUS_CHANGED_SUCCESSFULLY"
        ) {
          this.showSuccessMessage(
            "Success",
            "Student activation status changed successfully"
          );
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }

        //If the API returns other status codes besides 2xx, Handle these responses
        if (error.response) {
          if (error.response.data.message === "API MESSAGE HERE") {
            return;
          }
        } else {
          this.loadingError = true;
          this.showFailedMessage(
            "Unable to load Students",
            "An unexpected Error occurred, please try again"
          );
        }
      } finally {
        this.loading = false;
        this.submitting = false;
      }
    },

    async switchModuleSelectionStatus() {
      let request = await this.httpRequest({
        method: "PATCH",
        url: `students/toggle-student-modules-selection-disabled-by-retakes/${this.studentId}`,
        loadingPropertyName: "submitting",
        showSuccessMessage: true,
      });

      if (
        request &&
        request.success &&
        request.message == "Operation Successful"
      ) {
        //
      }
    },

    async showPassword() {
      this.StaffPassword = "";
      try {
        this.submitting = true;
        let request = await this.$http.get(
          `students/default_password/${this.studentId} `
        );
        if (request.data.message == "STUDENT_PASSWORD_RETRIEVED_SUCCESFULLY") {
          this.showPasswordBool = true;
          this.StudentPassword = request.data.default_pass;
        } else if (request.data.message == "STUDENT_HAS_NO_DEFAULT_PASSWORD") {
          this.showWarningMessage(
            "Warning",
            "Student has no default password."
          );
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }

        //If the API returns other status codes besides 2xx, Handle these responses
        if (error.response) {
          if (error.response.data.message === "API MESSAGE HERE") {
            return;
          }
        } else {
          this.showFailedMessage(
            "Unable to load Password",
            "An unexpected Error occurred, please try again"
          );
        }
      } finally {
        this.submitting = false;
      }
    },

    async resetPassword() {
      this.StaffPassword = "";

      try {
        this.submitting = true;
        let request = await this.$http.patch(
          `students/default_password/${this.studentId}`
        );
        if (
          request.data.message ==
          "STUDENT_DEFAULT_PASSWORD_CHANGED_SUCCESSFULLY"
        ) {
          this.showPasswordBool = true;
          this.StudentPassword = request.data.default_password;
          return this.showSuccessMessage(
            "Success",
            "Password reset successfully"
          );
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }

        //If the API returns other status codes besides 2xx, Handle these responses
        if (error.response) {
          if (error.response.data.message === "API MESSAGE HERE") {
            return;
          }
        } else {
          this.showFailedMessage(
            "Unable to load Password",
            "An unexpected Error occurred, please try again"
          );
        }
      } finally {
        this.submitting = false;
      }
    },

    async deleteStudent() {
      // return; // eslint-disable-next-line no-unreachable
      await this.$confirm(
        "This will permanently delete this student. Do you want to continue?",
        "Confirm delete",
        {
          // eslint-disable-line no-unreachable
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );
      try {
        this.submitting = true;
        let request = await this.$http.delete(
          `students/delete-one/${this.studentId}`
        );
        if (
          request.data.success &&
          request.data.message == "STUDENT_DELETED_SUCCESSFULLY"
        ) {
          this.showSuccessMessage("Success", "Student deleted successfully!");
          this.studentDetailsModal = false;
          this.submitting = false;
        } else throw "UNEXPECTED ERROR OCCURRED";
      } catch (error) {
        if (error.message == "Network Error") {
          this.submitting = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }

        //If the API returns other status codes besides 2xx, Handle these responses
        if (error.response) {
          if (error.response.data.message === "API MESSAGE HERE") {
            return;
          }
        } else {
          this.showFailedMessage(
            "Unable to delete Student",
            "An unexpected Error occurred, please try again"
          );
        }
      } finally {
        this.submitting = false;
      }
    },

    editStudent(studentId) {
      this.$router.push({
        name: "EditStudent",
        params: {
          studentId,
        },
      });
    },
    onOpenDetailedInfoDialog(studentId) {
      this.studentId = studentId;
      this.showDetailedInfoDialog = true;
    },
    onCloseDetailedInfoDialog() {
      this.showDetailedInfoDialog = false;
      this.handleClose();
    },
  },
};
</script>

<style scoped></style>
