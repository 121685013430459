<template>
  <div>
    <!-- <el-button
      :class="[$store.getters.canStaffCrud?'show_list':'hide_list', 'redBtn']"
      v-if="$store.getters.canStaffCrud"
      style="width: 150px; position: relative; bottom: 0px;"
      class="z-depth-0"
      @click="openStaffModal"
    >
      Add Staff
    </el-button> -->

    <section>
      <div class="container">
        <div class="staff_dialogs">
          <el-dialog
            title="Add staff members"
            :visible="isVisible"
            @open="onOpen()" 
            :destroy-on-close="true"
            :before-close="handleClose"
          >
            <div v-loading="staff_submiting">
              <el-form
                :model="staffRuleForm"
                label-position="top"
                :rules="staffRules"
                ref="staffRuleForm"
                label-width="120px"
                class="demo-staffRuleForm"
              >
                <div class="row">
                  <div class="col-md-6">
                    <el-form-item
                      label="First Name"
                      prop="first_name"
                    >
                      <el-input v-model="staffRuleForm.first_name"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <el-form-item
                      label="Last Name"
                      prop="last_name"
                    >
                      <el-input v-model="staffRuleForm.last_name"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <el-form-item
                      label="Email"
                      prop="email"
                    >
                      <el-input
                        type="email"
                        v-model="staffRuleForm.email"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <el-form-item
                      label="Phone Number"
                      prop="phone_number"
                    >
                      <el-input v-model="staffRuleForm.phone_number"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <el-form-item
                      label="Assign Staff Roles"
                      prop="checkedRoles"
                    >
                      <el-select
                        @keyup.enter="addStaff"
                        @change="onRoleChange()"
                        v-model="staffRuleForm.checkedRoles"
                        multiple
                        style="width: 100%;"
                      >
                        <el-option
                          v-for="role of this.staff_roles"
                          :key="role.value"
                          :label="role.label"
                          :value="role.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div
                    class="col-md-6"
                    :class="[selectedRoles ? 'fd_chosen' : 'fd_Notchosen']"
                  >
                    <el-form-item
                      label="Choose Dean's Faculty"
                      prop="faculty"
                      :class="[selectedFaculty ? 'fd_faculty' : 'fd_Notfaculty']"
                    >
                      <el-select
                        v-model="faculty"
                        filterable
                        placeholder="Choose A Faculty"
                      >
                        <el-option
                          v-for="faculty in faculties"
                          :key="faculty.faculty_id"
                          :label="faculty.title"
                          :value="faculty.faculty_id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item
                      label="Choose Department for this (HOD)"
                      prop="department"
                      :class="[selectedDepartment ? 'fd_dept' : 'fd_Notdept']"
                    >
                      <el-select
                        v-model="department"
                        filterable
                        placeholder="Choose A Department"
                      >
                        <el-option
                          v-for="department in departments"
                          :key="department.department_id"
                          :label="department.department_name"
                          :value="department.department_id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>

                  </div>

                  <div class="col-md-6">
                    <el-form-item
                      label="Full Time/Part Time"
                      prop="full_part_time"
                    >
                      <el-select
                        @keyup.enter="addStaff"
                        v-model="staffRuleForm.full_part_time"
                      >
                        <el-option
                          label="Full Time"
                          value="FULL_TIME"
                        ></el-option>
                        <el-option
                          label="Part Time"
                          value="PART_TIME"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <el-form-item
                      label="Gender"
                      prop="gender"
                    >
                      <el-radio-group v-model="staffRuleForm.gender">
                        <el-radio label="MALE">Male</el-radio>
                        <el-radio label="FEMALE">Female</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </div>
                </div>

                <el-form-item class="text-center">
                  <el-button
                    type="primary"
                    @click="addStaff('staffRuleForm')"
                  >Add Staff Member</el-button>
                </el-form-item>
              </el-form>

              <div class="text-center">
                <div class="text-center">
                  <small></small>
                </div>
                <div class="text-center">
                  <!-- <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                </div>
              </div>

            </div>
          </el-dialog>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
// import { validationMixin } from "vuelidate";
// import { required } from "vuelidate/lib/validators";
export default {
  // mixins: [validationMixin],

  data() {
    return {
      // addStaffModal: false,

      loading: false,
      submiting: false,
      loadingError: false,
      staff_submiting: false,
      faculties: [],
      departments: [],
      selectedRoles: false,
      selectedFaculty: false,
      selectedDepartment: false,
      staffData: {},

      faculty: "",
      department: "",

      staff_roles: [
        {
          value: "AR",
          label: "Academic Registrar (AR)",
        },
        {
          value: "HR",
          label: "Human Resource (HR)",
        },
        {
          value: "BURSAR",
          label: "Bursar",
        },
        {
          value: "DEAN",
          label: "Dean",
        },
        {
          value: "HOD",
          label: "Head of Department (HOD)",
        },
        {
          value: "QA",
          label: "Quality Assurance",
        },
        {
          value: "LECTURER",
          label: "Lecturer",
        },
        {
          value: "SUPER_ADMIN",
          label: "Super Admin",
        },
        {
          value: "VC",
          label: "Vice Chancellor",
        },
        {
          value: "STUDENT_APPLICATIONS",
          label: "Student Applications",
        },
        {
          value: "DEAN_OF_STUDENTS",
          label: "Dean of Students",
        },
        {
          value: "NON_TEACHING_STAFF",
          label: "Non Teaching Staff",
        },
        {
          value: "CAFETERIA_ADMIN",
          label: "Cafeteria Admin",
        },
        {
          value: "WEBSITE_ADMIN",
          label: "Website Admin",
        },
      ],

      staffRuleForm: {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        checkedRoles: [],
        department: "",
        full_part_time: "",
        gender: "",
      },

      staffRules: {
        first_name: [
          {
            required: true,
            message: "First Name is required",
            trigger: "blur",
          },
        ],
        last_name: [
          { required: true, message: "Last Name is required", trigger: "blur" },
        ],
        email: [
          { required: true, message: "Email is required", trigger: "blur" },
        ],
        phone_number: [
          {
            required: true,
            message: "Phone Number is required",
            trigger: "blur",
          },
        ],
        checkedRoles: [
          { required: true, message: "Roles are required", trigger: "change" },
        ],
        full_part_time: [
          {
            required: true,
            message: "Full or Part Time is required",
            trigger: "change",
          },
        ],
        gender: [
          { required: true, message: "Gender is required", trigger: "change" },
        ],
      },
    };
  },

  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    onOpen() {
      // this.addStaffModal = true;
      this.getFaculties();
      this.getDepartments();
      // this.faculty = "";
      // this.department = "";
    },

    handleClose(done) {
      this.clearInputs();
      this.$emit("close-dialog");
      done();
    },

    clearInputs() {
      this.staffRuleForm.first_name = "";
      this.staffRuleForm.last_name = "";
      this.staffRuleForm.email = "";
      this.staffRuleForm.phone_number = "";
      this.staffRuleForm.gender = "";
      this.staffRuleForm.checkedRoles = [];
    },

    onRoleChange() {
      this.faculty = "";
      this.department = "";
      console.log(this.staffRuleForm.checkedRoles);
      if (this.staffRuleForm.checkedRoles.includes("DEAN")) {
        this.selectedRoles = true;
        this.selectedFaculty = true;
        this.selectedDepartment = false;
      } else if (this.staffRuleForm.checkedRoles.includes("HOD")) {
        this.selectedRoles = true;
        this.selectedDepartment = true;
        this.selectedFaculty = false;
      } else if (
        this.staffRuleForm.checkedRoles.includes("DEAN") &&
        this.staffRuleForm.checkedRoles.includes("HOD")
      ) {
        this.selectedRoles = true;
        this.selectedFaculty = true;
        this.selectedDepartment = true;
      } else {
        this.selectedRoles = false;
      }
    },

    async getFaculties() {
      try {
        this.staff_submiting = true;
        let request = await this.$http.get(`faculties/fetch-many`);
        if (
          request.data.success &&
          request.data.message == "Faculties fetched successfully"
        ) {
          this.faculties = request.data.faculties;
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          "Unable to load Faculties",
          "An unexpected error occurred, please try again"
        );
      } finally {
        this.staff_submiting = false;
      }
    },

    async getDepartments() {
      try {
        this.staff_submiting = true;
        let request = await this.$http.get(`departments/fetch-many`);
        if (
          request.data.success &&
          request.data.message == "DEPARTMENTS_RETRIEVED_SUCCESSFULLY"
        ) {
          this.departments = request.data.departments;
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          "Unable to load Departments",
          "An unexpected error occurred, please try again"
        );
      } finally {
        this.staff_submiting = false;
      }
    },

    async addStaff(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.staffRuleForm.checkedRoles.includes("HOD")) {
            this.staffData = {
              first_name: this.staffRuleForm.first_name,
              last_name: this.staffRuleForm.last_name,
              email: this.staffRuleForm.email,
              phone_number: this.staffRuleForm.phone_number,
              gender: this.staffRuleForm.gender,
              roles: this.staffRuleForm.checkedRoles,
              department_id: this.department,
              fulltime_parttime: this.staffRuleForm.full_part_time,
            };

            if (this.department == "") {
              this.showWarningMessage(
                "Required",
                "Department field is required"
              );
              return;
            }
          } else if (this.staffRuleForm.checkedRoles.includes("DEAN")) {
            this.staffData = {
              first_name: this.staffRuleForm.first_name,
              last_name: this.staffRuleForm.last_name,
              email: this.staffRuleForm.email,
              phone_number: this.staffRuleForm.phone_number,
              gender: this.staffRuleForm.gender,
              roles: this.staffRuleForm.checkedRoles,
              faculty_id: this.faculty,
              fulltime_parttime: this.staffRuleForm.full_part_time,
            };

            if (this.faculty == "") {
              this.showWarningMessage("Required", "Faculty field is required");
              return;
            }
          } else {
            this.staffData = {
              first_name: this.staffRuleForm.first_name,
              last_name: this.staffRuleForm.last_name,
              email: this.staffRuleForm.email,
              phone_number: this.staffRuleForm.phone_number,
              gender: this.staffRuleForm.gender,
              roles: this.staffRuleForm.checkedRoles,
              fulltime_parttime: this.staffRuleForm.full_part_time,
            };
          }

          this.loading = true;
          this.staff_submiting = true;

          try {
            const response = await this.axios.post(`staff/add`, this.staffData);

            if (
              response.data.success == true &&
              response.data.message == "STAFF_CREATED_SUCCESSFULLY"
            ) {
              this.showSuccessMessage(
                "Success",
                "Staff member added successfully"
              );
              this.$emit("on-refresh");
              this.addStaffModal = false;
              this.staff_submiting = false;
              this.clearInputs();
            } else {
              this.showFailedMessage(
                "Unexpected Error",
                "An unexpected error occurred. Please try again"
              );
              this.staff_submiting = false;
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.showFailedMessage(
                "Connection failed",
                "A network error occurred, please try again."
              );
            } else if (error.response) {
              if (error.response.data.message == "PHONE_NUMBER_EXISTS") {
                this.showWarningMessage(
                  "Duplicate Phone Number",
                  "Phone number already in use. Please choose another one"
                );
              } else if (error.response.data.message == "INVALID_EMAIL") {
                this.showWarningMessage(
                  "Invalid Email",
                  "You entered an invalid Email"
                );
              } else if (
                error.response.data.message == "INVALID PHONE NUMBER"
              ) {
                this.showWarningMessage(
                  "Invalid Phone Number",
                  "The phone number you provided is invalid"
                );
              } else if (
                error.response.data.message == "STAFF ROLES ARE REQUIRED"
              ) {
                this.showWarningMessage(
                  "Roles Required",
                  "Staff Roles are required"
                );
              } else if (
                error.response.data.message ==
                "FACULTY_ID_IS_REQUIRED_FOR_A_DEAN"
              ) {
                this.showWarningMessage(
                  "Faculty Required",
                  "For a Dean Role, a faculty must be provided"
                );
              } else if (
                error.response.data.message ==
                "DEPARTMENT_ID_IS_REQUIRED_FOR_HOD"
              ) {
                this.showWarningMessage(
                  "Department Required",
                  "For an HOD, Department is required"
                );
              } else if (
                error.response.data.message == "UNACCEPTED ROLE PROVIDED"
              ) {
                this.showWarningMessage(
                  "Not Acceptable",
                  "One of the Roles you entered is not allowed"
                );
              } else if (
                error.response.data.message == "EMAIL_ALREADY_IN_USE"
              ) {
                this.showWarningMessage(
                  "Already in Use",
                  "The supplied email is already in use by another staff member"
                );
              }
            } else {
              this.showFailedMessage(
                "Unexpected Error",
                "An unexpected error occurred. Please try again"
              );
            }
            this.staff_submiting = false;
          } finally {
            this.loading = false;
            this.staff_submiting = false;
          }
        } else {
          return false;
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

input[type="text"],
input[type="email"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.to-an-array {
  display: flex;
  align-items: center;
}

.to-an-array > div {
  margin-right: 20px;
}

.fd_chosen {
  display: block;
}

.fd_Notchosen {
  display: none;
}

.fd_faculty,
.fd_dept {
  display: block;
}

.fd_Notfaculty,
.fd_Notdept {
  display: none;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_add_section {
    display: block;
  }
  .addBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .search_add_section {
    display: block;
  }
  .addBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
.staff_dialogs .el-dialog {
  width: 60%;
}

.search_by_input {
  width: 400px !important;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .staff_dialogs .el-dialog {
    width: 50%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .staff_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .staff_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .staff_dialogs .el-dialog {
    width: 90%;
  }
}
</style>
